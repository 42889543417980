import { PayrollService } from "../../../_service";
import { useAppSelector } from "../../../_app";
import { useEffect, useState } from "react";
import { Card, Container, Heading, Navlink } from "../Styles";
import { Loader, NavigateBack } from "../../../components";
import { H3Heading } from "../../appSettings/AppSettingsStyles";

const PayslipCalculation = () => {
  const employee = useAppSelector((state) => state?.employee);
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const get = async () => {
    try {
      setLoading(true);
      const da = await PayrollService._getPayslipCalculationPageData(
        employee?.employee_info?.id,
        employee?.last_date_of_current_month
      );
      setData(da);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    get();
  }, []);

  const keyOrder = [
    "income",
    "deduction",
    "allowance",
    "reimbursement",
    "other",
  ];

  

  return (
    <div style={{ position: "relative" }}>
      <div style={{ display: "flex", gap: 20 }}>
        <NavigateBack />
        <H3Heading>
          Add Payslip Item - {employee.currentEmployee.name}
        </H3Heading>
      </div>{" "}
      {loading && <Loader />}
      <Container>
        {keyOrder.map((type: any) => {
          return (
            data?.data?.calculation_list?.filter(
              (_: any) => _.calculation_type === type
            )?.length > 0 && (
              <Card key={type}>
                <Heading>{type?.replaceAll("_", " ") || ""}</Heading>
                {data?.data?.calculation_list
                  ?.filter((_: any) => _.calculation_type === type)
                  ?.sort((a:any, b:any)=>a.calculation_name?.toLowerCase()?.localeCompare(b.calculation_name?.toLowerCase()))
                  ?.map((item: any) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Navlink
                          to={`/dashboard/EmployeePayslipAddField/${item.calculation_code}/${item?.pc_id}`}
                          key={item.calculation_code}
                          state={item}
                        >
                          {item?.calculation_name}
                        </Navlink>
                        <span>{item.ci ? "(Custom)" : ""}</span>
                      </div>
                    );
                  })}
              </Card>
            )
          );
        })}
      </Container>
    </div>
  );
};

export default PayslipCalculation;
