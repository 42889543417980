import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AuthHeader } from "../../components/header";
import { useAppSelector } from "../../_app";
import { AppSettingService } from "../../_service";
import { CustomButton, CustomeCkeckboxField, Loader } from "../../components";
import cloneDeep from "lodash.clonedeep";
import { Notify, Utils } from "../../utils";
import CustomLoader from "../../components/CustomLoader";
import { LoaderContainer } from "../../CommonStyles";
import { H3Heading, Heading } from "./AppSettingsStyles";
import {
  MultiSelectButton,
  ReportHorizontalSeparator,
  ReportSectionHeading,
} from "../reports/ReportFilterPageStyles";
import { BsPlusCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const BuiltInItemsListing = () => {
  const user = useAppSelector((state) => state.user);
  const defaultCompanyId = user.defaultCompnayId;
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [settingsData, setSettingsData] = useState<any>(null);
  const [statusData, setStatusData] = useState<any>({});

//   ---- new api ----
const getGlobalPayItemsListing = async () => {
    setIsLoading(() => true);
    try {
      const res = await AppSettingService._getGlobalPayItemsListing();
      if (res.status >= 200 && res.status < 300) {
        let data = res?.data?.data;
        setSettingsData(data);
      }
    } catch (e: any) {
        Utils.showErrorMessage(e);
    }
    setIsLoading(() => false);
  };

  useEffect(() => {
    getGlobalPayItemsListing();
  }, []);


  if (isLoading) {
    return (
      <div>
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      </div>
    );
  }


  const itemOrder = [
    "income",
    "allowance",
    "summary",
    "deductions",
    "reimbursement",
    "other",
    "employer_contribution",
    "quantity",
  ]

  const sortedEntries = Object.entries(settingsData?.built_in_items || {}).sort(([key1], [key2]) => {
    // const order = [...itemOrder];
    const order = [...new Set([...itemOrder, ...Object.entries(settingsData?.built_in_items || {}).map((x:any) => x[0])])];
    console.log("finalOrder", order);
    return order.indexOf(key1) - order.indexOf(key2);
  });


  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "start",
          flexDirection: "column",
          width: "80%",
        }}
      >
        {/* <p
          style={{
            fontSize: "1.75rem",
            color: "rgb(71, 101, 117)",
            fontWeight: 600,
            width: "100%",
            borderBottom: "1px solid black",
            paddingBottom: "0.5rem",
            marginBottom: "1rem",
          }}
        >
          {"Global Custom Reports"}
        </p> */}

        <div
          style={{
            marginTop: "0.75rem",
            width: "100%",
            display: 'flex',
            rowGap: '1rem',
            columnGap: '1.5rem',
            flexDirection: 'column',
            maxHeight: '80vh',
            flexWrap: 'wrap',
            justifyContent: 'start',
            alignContent: 'start',
          }}
        >
          {settingsData &&
            sortedEntries?.length > 0 &&
            sortedEntries?.map((item: any) => (
              <div
                style={{
                  marginBottom: "0.5rem",
                  width: "40%",
                  padding: "0.5rem",
                  borderRadius: "0.25rem",
                  border: "1px solid #aaaaaa",
                  backgroundColor: "#F3F3F3",
                }}
              >
                {/* <p
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: 700,
                    textTransform: "capitalize",
                    marginBottom: "0.5rem",
                  }}
                >
                  {item[0].replace("_", " ")}
                </p> */}
                {/* <ReportSectionHeading>
                  {item[0].replace("_", " ")}                  
                </ReportSectionHeading> */}

                <div style={{ display: "flex", gap: 20, justifyItems: "space-between", alignItems: "center",  }}>
                  <Heading>
                    {item[0] === "allowance" ? "Allowances" : item[0] === "summary" ? "Gross Pay" : item[0].replace("_", " ")}{" "}
                  </Heading>
                  {/* <MultiSelectButton onClick={() => handleSelectAllForSection(item[1])}>
                    All
                  </MultiSelectButton>
                  <MultiSelectButton onClick={() => handleSelectNoneForSection(item[1])}>
                    None
                  </MultiSelectButton> */}
                  {/* <p onClick={() => {
                      navigate("/dashboard/add-item");
                    }}
                    style={{
                      width: 'fit-content',
                      padding: "0.25rem 0.75rem",
                      cursor: "pointer",
                      border: '1px solid #03b381',
                      borderRadius: "0.25rem",
                      color: '#03b381',
                      fontWeight: 500,
                      fontSize: '1rem',
                      display: 'flex',
                      alignItems: 'center',
                      gap: "0.5rem",
                      marginLeft: 'auto'
                    }}>
                      <BsPlusCircleFill />
                        Add
                  </p> */}
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection:'column',
                    flexWrap: 'wrap',
                    gap: "0.625rem",
                    marginTop: "0.5rem",
                    width: "100%",
                    maxHeight: '40vh',
                    justifyContent: 'start',
                    alignItems: 'start'
                  }}
                >
                  {item[1]?.map((x: any) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "1rem",
                        alignItems: "center",
                        width: "fit-content",
                        // marginBottom: "0.25rem",
                        color: "#333333",
                      }}
                    >
                      <p>{Utils.formateStringWithUnderscore(x.name)}</p>
                      {/* <input
                        type="checkbox"
                        checked={statusData[x.code]}
                        onChange={(e) =>
                          handleCheckbox(e.target.checked, x.code)
                        }
                        disabled={disabledList?.includes(x.code)}
                        style={{
                          width: "1rem",
                          height: "1rem",
                        }}
                      /> */}
                    </div>
                  ))}
                </div>

                {/* <ReportHorizontalSeparator /> */}
              </div>
            ))}
        </div>

        {/* <CustomButton
          title="Save"
          onClick={onSave}
          styles={{ marginRight: "2rem", width: "fit-content  " }}
        /> */}
      </div>
    </div>
  );
};

export default BuiltInItemsListing;
