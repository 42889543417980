import apiClient from "./BaseService";

import { URL } from "../config";

const _getCustomInput = async () => apiClient.get(URL.GET_CUSTOM_INPUT);

const _getCustomInputOption = async () =>
  apiClient.get(URL.GET_CUSTOM_INPUT_OPTION);

const _getCustomIR8AList = async (type: string) =>
  apiClient.get(URL.GET_CUSTOM_INPUT_IR8A_LIST + type);

const _getCustomCpfList = async () =>
  apiClient.get(URL.GET_CUSTOM_INPUT_CPF_LIST);

const _getUpdatedCustomInputs = async () => apiClient.get(URL.ADD_CUSTOM_INPUT);

const _addCustomInputs = async (data: any) =>
  apiClient.post(URL.ADD_CUSTOM_INPUT, data);

const _updateCustomInputs = async (id: any, data: any) =>
  apiClient.put(URL.ADD_CUSTOM_INPUT + `${id}/`, data);

const _deleteCustomInputs = async (id: any) =>
  apiClient.delete(URL.ADD_CUSTOM_INPUT + `${id}/`);

const _getCustomReportSettings = async (companyId: any, level: string) =>
  apiClient.get(URL.GET_CUSTOM_REPORT_SETTINGS + companyId + `&level=${level}`);

const _getGlobalPayItemsListing = async () =>
  apiClient.get(URL.GET_GLOBAL_PAY_ITEMS);

const _getCompanyPayItemsListing = async (companyId: any) =>
  apiClient.get(URL.GET_COMPANY_PAY_ITEMS + `&company_id=${companyId}`);

const _saveCustomReportSettings = async (data: any) =>
  apiClient.post(URL.SAVE_CUSTOM_REPORT_SETTINGS, data);


export const AppSettingService = {
  _getCustomInput,
  _getCustomInputOption,
  _getCustomIR8AList,
  _getCustomCpfList,
  _getUpdatedCustomInputs,
  _addCustomInputs,
  _updateCustomInputs,
  _deleteCustomInputs,
  _getCustomReportSettings,
  _saveCustomReportSettings,
  _getGlobalPayItemsListing,
  _getCompanyPayItemsListing,
};


