import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../_app";
import { ServiceCodeService } from "../../_service";
import { Notify, Utils } from "../../utils";
import { Constant } from "../../config";
import { LoaderContainer } from "../../CommonStyles";
import { CustomButton, CustomSelectField, Loader, Tooltip } from "../../components";
import { Heading } from "../appSettings/AppSettingsStyles";
import { IRASTable } from "../filing/CPFFilingStyles";
import { TableHeader } from "../../components/table";
import { TableRow } from "../payslip/PayslipStyles";
import moment from "moment";
import { FaReceipt } from "react-icons/fa";
import { IoIosSend } from "react-icons/io";
import { MdOutlineFileDownload, MdPreview } from "react-icons/md";
import ReconcileModal from "../appSettings/CustomerInvoicing/ReconcileModal";
import { SearchField } from "./component";

const GlobalInvoicesPastMonthYear = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [invoiceData, setInvoiceData] = useState<any>([]);

    const [searchParams, setSearchParams] = useState('')
    const [toMonth, setToMonth] = useState('01');
    const [fromMonth, setFromMonth] = useState('01');
    const [year, setYear] = useState(
        `${moment().year()-1}`
    );

    const navigate = useNavigate();
    const location = useLocation();


    const user = useAppSelector((state) => state.user);

    const companyId = useAppSelector((state) => state.user.defaultCompnayId);

    const serviceCodeTableHeaders = [
        {
            name: "Invoice No.",
            width: "10%",
        },
        {
            name: "Inovice Date",
            width: "10%",
        },
        {
            name: "SPC Code",
            width: "8%",
        },

        {
            name: "Customer Name",
            width: "25%",
        },
        {
            name: "Total Amount",
            width: "10%",
        },

        {
            name: "Status",
            width: "10%",
        },
        {
            name: "Action",
            width: "10%",
        },
    ];


    const getYearRange = (year: number = moment().year()) => {
        const startYear = year - 10;
        const yearsRange = [startYear];

        for (let i = 1; i < 10; i++) {
            yearsRange.push(startYear + i);
        }
        return yearsRange;
    };

    const yearOptions = getYearRange()
    console.log("new : yearOptions", yearOptions);


    // const convertToDateFormat = (fromMonth: string, toMonth: string, year: string) => {
    //     const fromDate = `${year}-${fromMonth.padStart(2, '0')}-01`;
    //     const toDate = `${year}-${toMonth.padStart(2, '0')}-01`;

    //     return { fromDate, toDate };
    // };

    // const { fromDate, toDate } = convertToDateFormat(fromMonth, toMonth, year);



    const getInvoiceListing = async () => {
        setIsLoading(() => true);

        try {
            const { data, status } = await ServiceCodeService._getInvoicePastMonthList(
                Utils.getStartDateOfMonth(year,fromMonth),
                Utils.getStartDateOfMonth(year,toMonth)
            );

            if (status === 200) {
                setInvoiceData(data?.data);
            }
        } catch (error:any) {
           Utils.showErrorMessage(error);
               
            // Notify(Constant.common.something_went_wrong, 0);
        }
        setIsLoading(() => false);
    };


    const handlePDFDownload = async (invoiceId: any) => {
        try {
            let response = await ServiceCodeService?._downloadInvoice(invoiceId);
            if (response.status >= 200 && response.status < 300) {
                const blob = new Blob([response.data], { type: "application/pdf" });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `invoice.pdf`; // Set the desired filename for the downloaded file
                document.body.appendChild(a);
                a.click();
            }
        } catch (error) {
            // Notify(Constant.common.something_went_wrong, 0);
            Notify("No PDF Available", 0);
        }
    };

    useEffect(() => {
        getInvoiceListing();
    }, []);

    useEffect(() => {
        if(searchParams)
        {
            getSearchInvoiceHandler();
        }
        else
        {
            getInvoiceListing();
        }
    }, [searchParams]);

    useEffect(() => {
        getInvoiceListing();
    }, [fromMonth,toMonth,year]);



    const getSearchInvoiceHandler = async () => {
        const { data, status } = await ServiceCodeService?._getsearchinvoiceList(searchParams)
        try {
            if (status === 200) {
                setInvoiceData(data?.data)
            }

        } catch (error: any) {

        }

    }
    console.log(Utils.MONTH_OPTIONS);



    return (
        <div>
            {isLoading ? (
                <LoaderContainer>
                    <Loader />
                </LoaderContainer>
            ) : (
                <>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "0.5rem",
                            width: "100%"
                        }}
                    >
                        <Heading>Global Invoicing Completed Past Month / Year  </Heading>
                        {user.permission === "full" &&
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',

                            }}>

                                {/* <CustomButton
                            title=" Print Global Report"
                            type="secondary"
                            onClick={() => navigate("generate")}
                        /> */}
                                <SearchField
                                    value={searchParams}
                                    onChangeText={setSearchParams}
                                />
                            </div>

                        }


                    </div>
                    <div
                        style={{ display: "flex", width: "50%", alignItems: "center", paddingBottom: '1rem' }}
                    >

                        <p style={{ margin: '1rem' }}>Year: </p>
                        <CustomSelectField
                            value={year}
                            onChangeOption={(value: any) => setYear(value)}
                            option={yearOptions?.reverse().map((month: any) => ({
                                key: month,
                                value: month,
                            }))}
                            disabled={isLoading}
                            isDate={true}
                        />
                        <p style={{ margin: '1rem' }}>From: </p>
                        <CustomSelectField
                            value={fromMonth}
                            onChangeOption={(value: any) => setFromMonth(value)}
                            option={Utils.MONTH_OPTIONS?.map((month) => ({
                                key: month.key,
                                value: month.value,
                            }))}
                            disabled={isLoading}
                            isDate={true}
                        />
                        <p style={{ margin: '1rem' }}>To: </p>

                        <CustomSelectField
                            value={toMonth}
                            onChangeOption={(value: any) => setToMonth(value)}
                            option={Utils.MONTH_OPTIONS?.map((month) => ({
                                key: month.key,
                                value: month.value,
                            }))}
                            disabled={false}
                            isDate={true}
                        />
                    </div>
                    <IRASTable style={{
                        width: "100%",
                    }}>
                        <TableHeader
                            headerColumns={serviceCodeTableHeaders}
                            variant="filled"
                            styles={{ padding: "5px", textAlign: "left" }}
                        />
                        <tbody>
                            {invoiceData?.length > 0 ?
                                invoiceData
                                    .sort((a: any, b: any) => b.number - a.number)
                                    .map((item: any, index: any) => (
                                        <TableRow style={{ cursor: 'pointer' }} key={item.id}>
                                            <td
                                                style={{
                                                    textAlign: 'left',
                                                    paddingLeft: '1.5rem'
                                                }}>{item?.number || ""}</td>

                                            <td onClick={() => navigate("generate", {
                                                state: { ...item, 'isViewOnly': true },
                                            })} style={{
                                                padding: '0.3125rem'
                                            }}>{item?.date && ` ${moment(item?.date, "YYYY-MM-DD").format("DD MM YYYY").replaceAll(' ', '-')}`}</td>

                                            {/* service code */}
                                            <td onClick={() => navigate("generate", {
                                                state: { ...item, 'isViewOnly': true },
                                            })} >{item?.company_data?.spc_code || ""}</td>

                                            <td onClick={() => navigate("generate", {
                                                state: { ...item, 'isViewOnly': true },
                                            })} style={{
                                                padding: '0.3125rem'
                                            }}>
                                                {item?.company_data.company_name}
                                            </td>


                                            <td onClick={() => navigate("generate", {
                                                state: { ...item, 'isViewOnly': true },
                                            })} style={{
                                                textAlign: "right",
                                                padding: '0.3125rem',
                                                paddingRight: '6%'
                                            }}>{Utils.getCommanSepartedNumericStringWithDollar(item?.json_data?.grand_total || "")}</td>
                                            <td onClick={() => navigate("generate", {
                                                state: { ...item, 'isViewOnly': true },
                                            })} style={{
                                                padding: '0.3125rem'
                                            }}>
                                                <p style={{
                                                    textTransform: 'capitalize'
                                                }}>{item?.status === 'payment_received' && "Payment Received" || item?.status}</p>
                                            </td>
                                            <td
                                                style={{
                                                    display: 'flex',
                                                    gap: '1rem',
                                                    alignContent: 'center',
                                                    textAlign: "center",
                                                    padding: '0.3125rem'
                                                }}
                                            >

                                                {/* <Tooltip text="Preview">
                                            <CustomButton
                                                type={"secondary"}
                                                size="small"
                                                width="30px"
                                                title={""}
                                                onClick={() => navigate("generate", {
                                                    state: { ...item, 'isViewOnly': true },
                                                  })}
                                                icon={<MdPreview />}
                                            />
                                        </Tooltip> */}
                                                <Tooltip text="Download">
                                                    <CustomButton
                                                        type={"secondary"}
                                                        size="small"
                                                        width="30px"
                                                        title={""}
                                                        onClick={() => handlePDFDownload(item?.id)}
                                                        icon={<MdOutlineFileDownload />}
                                                    />
                                                </Tooltip>
                                            </td>
                                        </TableRow>
                                    ))
                                :
                                (
                                    <tr>
                                        <td colSpan={serviceCodeTableHeaders.length} style={{ textAlign: "center", padding: "8px 12px" }}>
                                            No results found.
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </IRASTable>


                </>
            )}
        </div>
    )
}

export default GlobalInvoicesPastMonthYear