import { format, parse } from "date-fns";
import { store } from "../_app";
import { resetDashboard } from "../_features/dashboard";
import { resetEmployee, resetMessages, resetUser } from "../_features";
import { resetPayroll } from "../_features/payroll";
import moment from "moment";
import { Notify } from "./toastPopup";
import { Constant } from "../config";
import XLSX from 'xlsx';

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const MONTH_FULL_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

/** Array for Options of month */
const MONTH_OPTIONS = [
  {
    key: "January",
    value: "1",
  },
  {
    key: "February",
    value: "2",
  },
  {
    key: "March",
    value: "3",
  },
  {
    key: "April",
    value: "4",
  },
  {
    key: "May",
    value: "5",
  },
  {
    key: "June",
    value: "6",
  },
  {
    key: "July",
    value: "7",
  },
  {
    key: "August",
    value: "8",
  },
  {
    key: "September",
    value: "9",
  },
  {
    key: "October",
    value: "10",
  },
  {
    key: "November",
    value: "11",
  },
  {
    key: "December",
    value: "12",
  },
];

const resetStoreData = async () => {
  // return store.getState().user.access;
  store.dispatch(resetDashboard());
  store.dispatch(resetEmployee());
  store.dispatch(resetMessages());
  store.dispatch(resetPayroll());
  store.dispatch(resetUser());
};
const getFormatedDate = (date?: string) => {
  const d = date ? new Date(date) : new Date();

  const day = d.getDate();
  const m = MONTHS[d.getMonth()];
  const y = d.getFullYear();
  return `${day} ${m} ${y}`;
};

const formateNumber = (_: any) => {
  const val = _;
  return val > 9 ? val : `0${val}`;
};
const getDate = () => {
  const d = new Date();
  const lastData = new Date(d.getFullYear(), d.getMonth() + 1, 0);
  return `${d.getFullYear()}-${formateNumber(
    d.getMonth() + 1
  )}-${lastData.getDate()}`;
};

const _date = (date?: string | Date) => {
  const d = date ? new Date(date) : new Date();

  const day = formateNumber(d.getDate());
  const m = formateNumber(d.getMonth() + 1);
  const y = d.getFullYear();
  return `${y}-${m}-${day}`;
};

const _dateToShow = (date?: string | Date) => {
  const d = date ? new Date(date) : new Date();

  const day = formateNumber(d.getDate());
  const m = formateNumber(d.getMonth() + 1);
  const y = d.getFullYear();
  return `${day}-${m}-${y}`;
};

const _timeToShow = (time?: string | Date) => {
  const t = time ? new Date(time) : new Date();
  return t.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true });
};

const validateEmail = (email: string) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};

const getEmployeeName = (
  type: string,
  firstName: string,
  lastName?: string
) => {
  if (type === "First Name Last Name") {
    return `${firstName}, ${lastName}`;
  } else if (type === "Last Name First Name") {
    return `${lastName}, ${firstName}`;
  } else {
    return `${firstName}`;
  }
};

const getNumberWithCommas = (x: number | string) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const getPriceFromString = (amount: string | undefined) => {
  if (amount === "" || amount === undefined) {
    return "";
  }
  return amount.replace(/[, %]/g, "");
};

const isNumberWithComma = (number: string | undefined) => {
  const val = getPriceFromString(number);

  return val.match(/^-?\d+$/) ? true : val.match(/^\d+\.\d+$/) ? true : false;
};

const sortString = (str: string) => {
  return str.split("").sort().join("") || "";
};

const convertToDateString = (dateString: string) => {
  const date = new Date(dateString);
  const formattedDate = format(date, "d MMMM yyyy");
  return formattedDate;
};

const differenceInDays = (date1: string, date2: string) => {
  const date1Formatted = new Date(date1)
  const date2Formatted = new Date(date2)

  var date1_ms = date1Formatted.getTime();
  var date2_ms = date2Formatted.getTime();

  // Calculate the difference in milliseconds
  var difference_ms = Math.abs(date2_ms - date1_ms);

  // Convert the difference back into days
  return Math.floor(difference_ms / (1000 * 60 * 60 * 24));
};

const differenceInMonths = (date1: Date, date2: Date) => {
  const d1Year = date1.getFullYear();
  const d2Year = date2.getFullYear();
  const d1Month = date1.getMonth();
  const d2Month = date2.getMonth();

  return (d1Year - d2Year) * 12 + (d1Month - d2Month);
};

const monthsPassed = (
  referenceSring: string,
  dateString: string
): { count: number; monthYear: string } => {
  const refDate = parse(referenceSring, "yyyy-MM-dd", new Date());
  const date = parse(dateString, "yyyy-MM-dd", new Date());
  const count = differenceInMonths(refDate, date) - 1;
  const monthYear = format(date, "MMMM yyyy");
  return { count, monthYear };
};

const isEmpty = (val: any) => {
  if (val === "" || val === undefined || val === null) {
    return true;
  }
  if (typeof val === "string" && val?.trim() === "") {
    return true;
  }
  return false;
};

const formateStringWithUnderscore = (string: string) => {
  if (isEmpty(string)) {
    return "";
  }
  let str = string.replaceAll("_", " ");
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const getDefaultYearRange = (year : number = moment().year()) => {
  const startYear = year - 3;
  const yearsRange = [startYear];

  for (let i = 1; i < 10; i++) {
    yearsRange.push(startYear + i);
  }
  console.log("yearRange", yearsRange);
  return yearsRange;
};

const generateYearArray = (startYear: number) => {
  const currentYear = new Date().getFullYear();
  const yearArray = [];

  for (let year = startYear; year < currentYear; year++) {
    yearArray.push(year);
  }

  return yearArray;
};

const generateYearArrayTillNow = (startYear: number) => {
  const currentYear = new Date().getFullYear();
  const yearArray = [];

  for (let year = startYear; year <= currentYear; year++) {
    yearArray.push(year);
  }

  return yearArray;
};

const monthNameNumberMap: { [key: string]: number } = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

const prependDollar = (num: any) => {
  return "$" + num;
};

const getLastDayOfMonth = (year: any, month: any) => {
  const date = new Date(year, month, 1);
  date.setDate(0);

  const lastDayFormatted = _date(date);

  return lastDayFormatted;
};

const addDecimalAndZeroes = (numberString: string) => {
  // Check if the number string contains a decimal point
  if (!numberString.includes(".")) {
    // If it doesn't, add ".00" and return the updated string
    return numberString + ".00";
  } else {
    // If it already has a decimal point, return the original string
    return numberString;
  }
};

const getPreviousMonthYear = (dateString: string) => {
  const d = new Date(dateString);
  d.setDate(1);
  d.setHours(-1);
  const previousYear = d.getFullYear();
  const previousMonth = d.getMonth() + 1; // Adding 1 since months are zero-based

  return { previousYear, previousMonth };
};

const getNextMonthYear = (dateString: string) => {
  const d = new Date(dateString);
  d.setDate(1);
  d.setMonth(d.getMonth() + 1);
  const nextYear = d.getFullYear();
  const nextMonth = d.getMonth() + 1; // Adding 1 since months are zero-based

  return { nextYear, nextMonth };
};

const getMonthRange = (monthStr: string) => {
  const parts = monthStr.split(" ");
  const year = parseInt(parts[1]);
  const monthIndex = MONTH_FULL_NAMES.indexOf(parts[0]);

  const firstDay = new Date(year, monthIndex, 1);
  const lastDay = new Date(year, monthIndex + 1, 0);

  return `${firstDay.toLocaleDateString()} to ${lastDay.toLocaleDateString()}`;
};

const dayMonthNameFormat = (inputDate: string) => {
  const months = MONTH_FULL_NAMES;

  const [year, month, day] = inputDate.split("-");
  const monthIndex = parseInt(month, 10) - 1;
  const formattedDate = `${day} ${months[monthIndex]} ${year}`;
  return formattedDate;
};

const removeDuplicates = (array: any, property: any) => {
  const uniqueValues: any = {};
  const result: any = [];

  for (const obj of array) {
    const value = obj[property];

    if (!uniqueValues[value]) {
      // If the value is not encountered yet, add it to the result array
      result.push(obj);
      uniqueValues[value] = true;
    }
  }

  return result;
};



const getCommanSepartedNumericStringWithDollar = (num: string | number): string => {
  if (typeof num === 'string') {
      // num = parseFloat(num);
      num = parseFloat(num.replace(/[$,]/g, ""));
  }
  if (isNaN(num) || typeof num === 'undefined' || num === null || !isFinite(num)) {
      // return '$0.00';
      return '';
  } else {
      return '$' + num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

function formatTimestamp(timestamp: string): string {
  const date = new Date(timestamp);
  const now = new Date();
  const diff = now.getTime() - date.getTime();

  // Convert milliseconds to seconds
  const seconds = Math.floor(diff / 1000);

  // Convert seconds to minutes
  const minutes = Math.floor(seconds / 60);

  // Convert minutes to hours
  const hours = Math.floor(minutes / 60);

  // Convert hours to days
  const days = Math.floor(hours / 24);

  if (days > 1) {
      return `${days} days ago`;
  } else if (hours > 1) {
      return `${hours} hours ago`;
  } else if (minutes > 1) {
      return `${minutes} minutes ago`;
  } else {
      return `less than a minute ago`;
  }
}

function getStartDateOfMonth(year:string, month:string) {
  return moment(`${year}-${month}-01`).startOf('month').format('YYYY-MM-DD');
}

function getLastDateOfMonth(year:string, month:string) {
  return moment(`${year}-${month}-01`).endOf('month').format('YYYY-MM-DD');
}

const validateNetPay = (netPay: any) => {
  if (
    !Number.isNaN(
      parseFloat(Utils.getPriceFromString(netPay))
    ) &&
    parseFloat(Utils.getPriceFromString(netPay)) >= 0
  ) {
    return true;
  }
}

const showErrorMessage = (error:any) => {
  if (error.response.data?.message) {
    Notify(error.response.data?.message, 0);
  } else {
    Notify(Constant.common.something_went_wrong, 0);
  }

}

const getLastDayOfPreviousMonth = (date: string) => {
  const givenDate = moment(date);
  const lastDateOfPreviousMonth = givenDate.clone().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
  return lastDateOfPreviousMonth;
}

const getFirstDayOfPreviousMonth = (date: string) => {
  const givenDate = moment(date);
  const firstDateOfPreviousMonth = givenDate.clone().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
  return firstDateOfPreviousMonth;
}

// worksheet parameter expects the result of XLSX.utils.aoa_to_sheet(combinedData) 
const autoFitWorksheetColumns = (worksheet:any) => {
  const columnWidths = [];
  const range = XLSX.utils.decode_range(worksheet['!ref']);
  
  for (let col = range.s.c; col <= range.e.c; col++) {
      let maxWidth = 0;
      for (let row = range.s.r; row <= range.e.r; row++) {
          const cellAddress = XLSX.utils.encode_cell({c: col, r: row});
          const cell = worksheet[cellAddress];
          if (cell && cell.v) {
              const cellLength = cell.v.toString().length;
              maxWidth = Math.max(maxWidth, cellLength);
          }
      }
      columnWidths.push({wch: maxWidth + 2}); // Adding a buffer for padding
  }
  
  worksheet['!cols'] = columnWidths; // Set the calculated widths

  return worksheet;
}

export const Utils = {
  formatTimestamp,
  getFormatedDate,
  resetStoreData,
  validateEmail,
  getEmployeeName,
  getNumberWithCommas,
  getPriceFromString,
  isNumberWithComma,
  getDate,
  _date,
  sortString,
  convertToDateString,
  monthsPassed,
  isEmpty,
  formateStringWithUnderscore,
  generateYearArray,
  monthNameNumberMap,
  prependDollar,
  generateYearArrayTillNow,
  MONTH_FULL_NAMES,
  _dateToShow,
  _timeToShow,
  MONTH_OPTIONS,
  getLastDayOfMonth,
  addDecimalAndZeroes,
  getPreviousMonthYear,
  getNextMonthYear,
  getMonthRange,
  dayMonthNameFormat,
  removeDuplicates,
  differenceInDays,
  getDefaultYearRange,
  getCommanSepartedNumericStringWithDollar,
  getStartDateOfMonth,
  getLastDateOfMonth,
  validateNetPay,
  showErrorMessage,
  getFirstDayOfPreviousMonth,
  getLastDayOfPreviousMonth,
  autoFitWorksheetColumns,
  
};
