import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Heading } from "./AppSettingsStyles";
import { useAppSelector } from "../../_app";
import { CompanyInfoModal } from "../../components";
import { NavOptions } from "../dashboard/component";
import CustomTooltip from "../../components/CustomTooltip";

const activeStyle = {
  color: "#212529",
  borderBottom: "3px solid #25b481",
};
const ManageGlobalReportNav = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  const navigate = useNavigate();
  const defaultCompany = useAppSelector((state) => state.user.defaultCompany);
  const id = useAppSelector((state) => state.employee.appSettingId);
  const permission = useAppSelector((state) => state.user.permission);

  const onClickLeaveOption = (props: any) => {
    navigate(props.navigate);
  };

  const showCompanyInfoModalPath = [
    "employer-details",
    "employer-filing-details",
    "pay-frequencies",
  ];

  const GlobalCustomReportTooltipText = () =>  <div style={{margin: '0.5rem 0.25rem', borderRadius:'8px', padding: "1rem", background: '#F4F6FF',  fontWeight:600 }}>
    <p>{`All items is the summary of "Custom Items" and "Built-in Items"`}</p>
  </div>

  return (
    <div>
      {id === "" &&
        showCompanyInfoModalPath.includes(pathname.split("/").pop() || "") && (
          <CompanyInfoModal />
        )}
      <div style={{display: 'flex', alignItems: 'center', gap: '0.75rem' ,}}>
        <Heading>
          {`${pathname.split("/").pop() === "manage-custom-report" ? "Manage Global Reports" : pathname.split("/").pop()?.split("-")?.join(" ")  }`}
        </Heading>
        {pathname.split("/").pop() === "manage-custom-report" && <CustomTooltip title={<GlobalCustomReportTooltipText />} placement={'bottom'}>
            {/* <img src="../../assets/info.svg" /> */}
            <img src="/info-outline.svg" style={{marginBottom: '10px'}} />
          </CustomTooltip>}
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          borderBottom: "1px solid #6c757d",
          marginBottom: "5px",
        }}
      >
        {permission === "full" && (
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
            className="employee-sublink"
            to="manage-custom-report"
          >
            Global Custom Reports
          </NavLink>
        )}
        <NavLink
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
          className="employee-sublink"
          to="custom-items"
        >
          Custom Items
        </NavLink>
        <NavLink
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
          className="employee-sublink"
          to="built-in-items"
        >
          Built-In Items
        </NavLink>
        
        
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default ManageGlobalReportNav;
