import apiClient from "./BaseService";
import { URL } from "../config";

const _getCategoryList = async () => apiClient.get(URL.CATEGORY_LIST);

const _leaveReportExport = async (searchQuery: string) =>
  apiClient.get(`${URL.DOWNLOAD_LEAVE_REPORT}${searchQuery}`);

const _leaveDaysReport = async (searchQuery: string) =>
  apiClient.get(`${URL.DOWNLOAD_LEAVE_DAYS_REPORT}${searchQuery}`);

const _leaveExpiryReport = async (searchQuery: string) =>
  apiClient.get(`${URL.DOWNLOAD_LEAVE_EXPIRY_REPORT}${searchQuery}`);

const _basicInfoReportData = async (searchQuery: string) =>
  apiClient.get(`${URL.GET_BASIC_INFO_DATA}?${searchQuery}`);

const _payslipExportData = async (searchQuery: string) =>
  apiClient.get(`${URL.GET_PAYSLIP_EXPORT_DATA}?${searchQuery}`);

const _getTransactionFilters = async () =>
  apiClient.get(URL.GET_TRANSACTION_FILTERS);

const _getTransactionExportData = async (searchQuery: string) =>
  apiClient.get(`${URL.GET_PAYSLIP_TRANSACTION_DATA}?${searchQuery}`);

const _getCustomReport = async (
  reportCode: string,
  companyId: any,
  dateFrom: string,
  dateTo: string,
  employeeIds: string,
) =>
  apiClient.get(
    `${URL.GET_CUSTOM_REPORT}?report_code=${reportCode}&company_id=${companyId}&date_from=${dateFrom}&date_to=${dateTo}&employees_ids=${employeeIds}&request_origin=web-get_report_data`
  );

export const ReportService = {
  _getCategoryList,
  _leaveReportExport,
  _leaveDaysReport,
  _leaveExpiryReport,
  _basicInfoReportData,
  _payslipExportData,
  _getTransactionFilters,
  _getTransactionExportData,
  _getCustomReport,
};
