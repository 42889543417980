import { IconContext } from "react-icons";
import { IoCaretBackCircle, IoCaretForwardCircle } from "react-icons/io5";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import Require from "./Require";
import { Container, Error, Label } from "./Styles";
import { FaCalendarAlt } from "react-icons/fa";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const getYear = (date: any) => {
  return new Date(date).getFullYear();
};

const getMonth = (date: any) => {
  return months[new Date(date).getMonth()];
};

const range = (a: any, b: any) => {
  let data = [];
  for (let i = a; i <= b; i++) {
    data.push(i);
  }
  return data;
};
const   DateField = (props: {
  label?: string;
  date: any;
  setDate: any;
  error?: string;
  note?: string;
  require?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  filterDate?: (date: any) => any;
  margin?: string;
  disabled?: boolean;
  labelWidth?: string;
  width?: string;
  isColumn?: boolean;
  startYear?: number;
  endYear?: number;
  openAsModal?: boolean;
}) => {
  const startYear = props.startYear || 2023  ;
  const endYear = props.endYear || new Date().getFullYear() + 100 || 3000;
  // const years = range(new Date().getFullYear() - 3, new Date().getFullYear() + 10);
  const years = range(startYear, endYear);

  return (
    <Container margin={props?.margin || ".8rem"} isColumn={props?.isColumn}>
      {props?.label && (
        <Label
          style={{
            width: props?.labelWidth
              ? props?.labelWidth
              : props?.isColumn
              ? "80%"
              : "30%",
          }}
        >
          {props.label}
          {props.require && <Require />}
        </Label>
      )}

      <div style={{ width: props?.label ? "60%" : "100%",  }}>
        <Select>
          <DatePicker
            popperClassName="some-custom-class"
            // popperPlacement="top-end"
            popperModifiers={[
              {
                name: "offset",
                options: {
                  offset: [5, 10],
                },
              },
              {
                name: "preventOverflow",
                options: {
                  rootBoundary: "viewport",
                  tether: false,
                  altAxis: true,
                },
              },
            ]}
            disabled={props.disabled ?? false}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }): any => (
              <div
                style={{
                  margin: 10,
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  style={{ border: "none" }}
                >
                  <IconContext.Provider value={{ color: "#000", size: "20px" }}>
                    <IoCaretBackCircle />
                  </IconContext.Provider>
                </button>
                <select
                  value={getYear(date)}
                  onChange={({ target: { value } }: any) => changeYear(value)}
                  onFocus={props.onFocus}
                  onBlur={props.onBlur}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <select
                  value={getMonth(date)}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                  style={{ border: "none" }}
                >
                  <IconContext.Provider value={{ color: "#000", size: "20px" }}>
                    <IoCaretForwardCircle />
                  </IconContext.Provider>
                </button>
              </div>
            )}
            dateFormat="dd-MM-yyyy"
            selected={props.date ? new Date(props.date) : null}
            onChange={(date: any) =>
              props.setDate(date?.toISOString().split("T")[0])
            }
            withPortal={props.openAsModal}
          />
        </Select>
        {props.error ? (
          <Error>{props.error || ""}</Error>
        ) : (
          props.note && <Error color="grey">{props.note}</Error>
        )}
      </div>
    </Container>
  );
};

export default DateField;

const Select = styled.div`
  width: 100%;
  position: relative;
`;
