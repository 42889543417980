import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../_app";
import { SelfService } from "../../../../_service/SelfService";
import { Constant } from "../../../../config";
import { Notify } from "../../../../utils";
import { IRASTable } from "../../../filing/CPFFilingStyles";
import { TableRow } from "../../../payslip/PayslipStyles";
import { TableColumn } from "../../../users/styles";
import {
  CommonModal,
  CustomButton,
  CustomTextField,
  Loader,
} from "../../../../components";
import {
  EmployeeTableActionButton,
  EmployeeUserTableHeader,
  EmployeeUserTableHeaderColumn,
  EmployeeUserTableHeaderEnableCol,
  EmployeeUserTableHeaderSmallColumn,
  EnableAllNoneButton,
} from "../SelfServiceStyles";
import { useNavigate } from "react-router-dom";
import { LoaderContainer } from "../../../../CommonStyles";
import { FilterBySelect, SearchField } from "../../component";
import EmployeeListModal from "../../../reports/EmployeeListModal/EmployeeListModal";
import styled from "styled-components";
import cloneDeep from "lodash.clonedeep";

const EmployeeUsers = () => {
  const [employeeUserList, setEmployeeUserList] = useState<any>([]);
  const [filterEmployeeUserList, setFilterEmployeeUserList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [originalEmployeeList, setOriginalEmployeeList] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [status, setStatus] = useState("0");
  const [showEmployeeModal, setShowEmployeeModal] = useState<boolean>(false);
  const [selectedEmployeeModalList, setSelectedEmployeeModalList] =
    useState<any>([]);

  // if the user interacted with the employee list in anyway then we will store the modified list
  const [modifiedEmployeeList, setModifiedEmployeeList] = useState<any>([]);

  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate();

  const getSelfServiceEmployeeList = async () => {
    setIsLoading(() => true);
    try {
      const { status, data } = await SelfService._getSelfServiceEmployeeList(
        user.defaultCompnayId
      );

      if (status === 200) {
        const employeeData = data.data;
        setEmployeeUserList([...employeeData]);
        setFilterEmployeeUserList([...employeeData]);
        setOriginalEmployeeList(() => JSON.parse(JSON.stringify(employeeData)));
        setSelectedEmployeeModalList(() =>
          employeeData.map((emp: any) => emp.user.id)
        );
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  const handleEnableChange = (userId: any) => {
    const employeeList = [...employeeUserList];
    const index = employeeList.findIndex((obj) => obj.user.id === userId);
    const is_staff = employeeList[index].user.is_staff;
    employeeList[index].user.is_staff = !is_staff;
    setEmployeeUserList(() => employeeList);
    setFilterEmployeeUserList(() => employeeList);
  };

  const handleAllEnable = () => {
    const employeeList = [...employeeUserList];
    employeeList.forEach((_, index) => {
      employeeList[index].user.is_staff = true;
    });
    setEmployeeUserList(() => employeeList);
    setFilterEmployeeUserList(() => employeeList);
  };

  const handleNoneEnable = () => {
    const employeeList = [...employeeUserList];
    employeeList.forEach((_, index) => {
      employeeList[index].user.is_staff = false;
    });
    setEmployeeUserList(() => employeeList);
    setFilterEmployeeUserList(() => employeeList);
  };

  const handleModifiedEmployeeList = (employee: any, isChecked: any) => {
    const employeeList = cloneDeep(modifiedEmployeeList);
    const index = employeeList.findIndex((obj:any) => obj.user.id === employee.user.id);
    if (index === -1) {
      employeeList.push(employee);
    } else {
      employeeList[index].user.is_staff = isChecked;
    }
    setModifiedEmployeeList(() => employeeList);
  }

  const handleModifiedEmployeeListAll = () => {
    const employeeList = cloneDeep(employeeUserList);
    employeeList.forEach((employee:any) => {
      employee.user.is_staff = true;
    });

    setModifiedEmployeeList(() => employeeList);
  }

  const handleModifiedEmployeeListNone = () => {
    const employeeList = cloneDeep(employeeUserList);
    employeeList.forEach((employee:any) => {
      employee.user.is_staff = false;
    });

    setModifiedEmployeeList(() => employeeList);
  }

  console.log("modifiedEmployeeList Outside", modifiedEmployeeList)

  const handleEmployeeUserSave = async () => {
    const positiveEmails: any = [];
    const negativeEmails: any = [];
    
    try {
      // originalEmployeeList.forEach((employee: any, index: any) => {
      //   const newUser = employeeUserList[index];
      //   if (employee.user.is_staff !== newUser.user.is_staff) {
      //     if (newUser.user.is_staff) {
      //       positiveEmails.push(newUser.user.email);
      //     } else {
      //       negativeEmails.push(newUser.user.email);
      //     }
      //   }
      // });

      modifiedEmployeeList.forEach((employee: any) => {
        if (employee.user.is_staff) {
          positiveEmails.push(employee.user.email);
        } else {
          negativeEmails.push(employee.user.email);
        }
      });

      const dataToSend = {
        true_emails: positiveEmails,
        false_emails: negativeEmails,
      };

      console.log("payload", dataToSend);

      const { status } = await SelfService._postSelfServiceEmployeeUser(
        dataToSend
      );

      if (status === 200) {
        Notify("Access sent to user's email", 1);
        getSelfServiceEmployeeList();
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const handleSelectModalAll = () => {
    const allEmpIds = filterEmployeeUserList.map((emp: any) => emp.user.id);
    setSelectedEmployeeModalList(() => allEmpIds);
  };

  const handleSelectEmployeeModal = (id: any) => {
    if (selectedEmployeeModalList.includes(id)) {
      let interArr = selectedEmployeeModalList;
      interArr = interArr.filter((em: any) => em !== id);
      setSelectedEmployeeModalList(() => interArr);
    } else {
      setSelectedEmployeeModalList(() => [...selectedEmployeeModalList, id]);
    }
  };

  const handleEmployeeModalSelectNone = () => {
    setSelectedEmployeeModalList(() => []);
  };

  useEffect(() => {
    if (status === "0") {
      setFilterEmployeeUserList(() => employeeUserList);
      setSelectedEmployeeModalList(() =>
        employeeUserList.map((emp: any) => emp.user.id)
      );
    } else if (status === "1") {
      const updatedData = employeeUserList.filter(
        (employeeUser: any) => employeeUser.user.is_active === true
      );
      setSelectedEmployeeModalList(() =>
        updatedData.map((emp: any) => emp.user.id)
      );
      setFilterEmployeeUserList(() => updatedData);
    } else {
      const updatedData = employeeUserList.filter(
        (employeeUser: any) => employeeUser.user.is_active === false
      );
      setSelectedEmployeeModalList(() =>
        updatedData.map((emp: any) => emp.user.id)
      );
      setFilterEmployeeUserList(() => updatedData);
    }
  }, [status]);

  useEffect(() => {
    getSelfServiceEmployeeList();
  }, []);

  return isLoading ? (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ) : (
    <div>
      <div style={{ display: "flex", gap: 20 }}>
        <Container style={{ marginBottom: 20 }}>
          <Text>Pay Frequency </Text>
          <Information style={{ cursor: "not-allowed" }}>
            Monthly, end..
          </Information>
        </Container>
        <Container style={{ marginBottom: 20 }}>
          <Text>Employees </Text>
          <Information onClick={() => setShowEmployeeModal(() => true)}>
            {selectedEmployeeModalList.length === 0
              ? "None"
              : selectedEmployeeModalList.length ===
                filterEmployeeUserList.length
              ? "All"
              : `${selectedEmployeeModalList.length} of ${filterEmployeeUserList.length}`}
          </Information>
        </Container>
        <FilterBySelect value={status} onChageOption={setStatus} />
        <SearchField value={searchText} onChangeText={setSearchText} />
      </div>
      <IRASTable>
        <EmployeeUserTableHeader>
          <EmployeeUserTableHeaderColumn>
            Employee Users
          </EmployeeUserTableHeaderColumn>
          <EmployeeUserTableHeaderColumn>E-mail</EmployeeUserTableHeaderColumn>
          <EmployeeUserTableHeaderSmallColumn>
            <EmployeeUserTableHeaderEnableCol>
              Enabled
              <EnableAllNoneButton onClick={() => {
                handleAllEnable();
                handleModifiedEmployeeListAll();
              }}>
                All
              </EnableAllNoneButton>
              <EnableAllNoneButton onClick={() => {
                handleNoneEnable();
                handleModifiedEmployeeListNone();
              }}>
                None
              </EnableAllNoneButton>
            </EmployeeUserTableHeaderEnableCol>
          </EmployeeUserTableHeaderSmallColumn>
          <EmployeeUserTableHeaderSmallColumn>
            Last Activity
          </EmployeeUserTableHeaderSmallColumn>
        </EmployeeUserTableHeader>
        <tbody>
          {filterEmployeeUserList
            .filter((filteredEmployeeUser: any) =>
              selectedEmployeeModalList.includes(filteredEmployeeUser.user.id)
            )
            .sort((a: any, b: any) => a.user.name.localeCompare(b.user.name))
            .map(
              (employeeUser: any, index: any) =>
                employeeUser?.user?.name
                  ?.toLowerCase()
                  .includes(searchText?.toLowerCase()) && (
                  <TableRow key={index}>
                    <TableColumn>{employeeUser?.user?.name || ""}</TableColumn>
                    <TableColumn>
                      <CustomTextField
                        title=""
                        value={employeeUser?.user?.email}
                        marginTop="0px"
                        disabled={true}
                      />
                    </TableColumn>
                    <TableColumn>
                      <input
                        type="checkbox"
                        style={{ width: 15 }}
                        checked={employeeUser?.user?.is_staff}
                        onChange={(e:any) => {
                          handleEnableChange(employeeUser.user.id);
                          handleModifiedEmployeeList(employeeUser, e.target.checked);
                        }
                        }
                      />
                    </TableColumn>
                    <TableColumn>{employeeUser?.user?.login || ""}</TableColumn>
                  </TableRow>
                )
            )}
        </tbody>
      </IRASTable>
      <EmployeeTableActionButton>
        <CustomButton
          type="secondary"
          title="Cancel"
          onClick={() => navigate("/dashboard/employee-payroll")}
        />
        <CustomButton title="Save" onClick={() => handleEmployeeUserSave()} />
      </EmployeeTableActionButton>
      <CommonModal
        handleClose={() => setShowEmployeeModal(false)}
        open={showEmployeeModal}
        updateData={() => setShowEmployeeModal(false)}
        body={
          <EmployeeListModal
            employeeList={filterEmployeeUserList}
            handleSelectAll={handleSelectModalAll}
            handleSelectNone={handleEmployeeModalSelectNone}
            selectedEmployeeList={selectedEmployeeModalList}
            handleSelectEmployee={handleSelectEmployeeModal}
          />
        }
        heading="Filter Employees"
        conformButtonText="Close"
        isCancelButton={false}
      />
    </div>
  );
};

export default EmployeeUsers;

const Container = styled.div`
  margin-bottom: 1rem;
  display: flex;
`;
const Text = styled.p`
  width: 110px;
  height: 30px;
  color: #fff;
  border: 1px solid #afb5ba;
  font-size: 0.8rem;
  background-color: #afb5ba;
  text-transform: none;
  display: flex;
  align-items: center;
  padding: 0 5px;
  border-radius: 3px 0 0 3px;
`;

const Information = styled.div`
  max-width: 120px;
  height: 30px;
  background-color: #087ca7;
  color: #fff;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  padding: 0 5px;
  text-align: center;
  display: flex;
  align-items: center;
`;
