import { useState, } from "react";

import { Notify } from "../../utils";
import { DeductionTableDataType } from "../../_types";
import { ManageDeductionService } from "../../_service";
import { CustomButton } from "../customButton";
import { CustomTextField } from "../customFormField";
import { useAppSelector } from "../../_app";
import { AiFillDelete } from "react-icons/ai";
import Tooltip from "../Tooltip";
import { Constant } from "../../config";
import { CommonModal } from "../modals";

interface ManageDeductionTableRowProps {
  s_no: number;
  data: DeductionTableDataType;
  syncData: () => void;
  isMalay: boolean;
}

const ManageDeductionTableRow = (props: ManageDeductionTableRowProps) => {
  const { data, s_no, syncData } = props;

  const [edit, setEdit] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const [open , setOpen] = useState<boolean>(false)
  const [amount, setAmount] = useState(data.amount);
  const [min_amount, setMin_amount] = useState(data.min_amount);
  const [max_amount, setMax_amount] = useState(data.max_amount);
  const [other, setOther] = useState(data.other);
  const user = useAppSelector((state) => state.user);
  const toggle = () => setEdit((prev) => !prev);

  console.log("Md data",data); 
  

  const handleCancel = () => {
    setAmount(data.amount);
    setMin_amount(data.min_amount);
    setMax_amount(data.max_amount);
    setOther(data.other);
    toggle();
  }


  const handleDeductionTableDataUpdate = async () => {
    setUpdating(true);
    const formData = {
      ...data,
      max_amount,
      min_amount,
      amount,
      other,
    };
    const res = await ManageDeductionService._updateDeductionData({
      id: data.id,
      data: formData,
    });

    if (res.status === 200) {
      syncData();
      Notify("Data Updated Successfully", 1);
    } else {
      Notify("Data Updating Failed", 0);
    }

    setUpdating(false);
    setEdit(false);
  };

  const handleDeleteManageDeduction = async (id: string) => {
    try {
      const { status } = await ManageDeductionService._deleteDeductionData(
        parseInt(id)
      );

      if (status >= 200 && status <= 300) {
        syncData();
        Notify("Service Code Deleted Successfully", 1);
       
      }
    } catch (error: any) {
      if (error?.response?.status === 400) {
        Notify(error.response.data?.message, 0);
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }

  };


 


  return (
    <tr>
      {edit ? (
        <>
          <td>{s_no + 1}</td>
          <td>
            <CustomTextField
              width="100px"
              type="number"
              value={min_amount}
              onChangeText={setMin_amount}
            />
          </td>
          <td>
            <CustomTextField
              width="100px"
              type="number"
              value={max_amount}
              onChangeText={setMax_amount}
            />
          </td>
          <td>
            <CustomTextField
              width="100px"
              type="number"
              value={amount}
              onChangeText={setAmount}
            />
          </td>
          {props.isMalay && (
            <td>
              {(parseFloat(amount) - parseFloat(other)) < 0 && <p style={{ fontSize: '0.875rem', fontWeight: '600', color: '#ff6665' }}>MBMF Cannot Be Negative</p>}
              <CustomTextField
                width="100px"
                type="number"
                value={(parseFloat(amount) - parseFloat(other)).toFixed(2)}
                disabled
              />
            </td>
          )}
          {props.isMalay && (
            <td>
              <CustomTextField
                width="100px"
                type="number"
                value={other}
                onChangeText={setOther}
              />
            </td>
          )}
          <td>
            <div style={{ display: "flex", gap: "0.25rem" }}>
              <CustomButton
                size="small"
                width="70px"
                title="Update"
                onClick={handleDeductionTableDataUpdate}
                disable={updating || (parseFloat(amount) - parseFloat(other)) < 0}
              />

              <img onClick={handleCancel} alt="cancel" title="Cancel" style={{ width: "2.2rem", cursor: 'pointer' }} src="/cancel.svg" />
            </div>
          </td>
        </>
      ) : (
        <>
          <td>{s_no + 1}</td>
          <td>
            {`> `}
            {data.min_amount}
          </td>
          <td>
            {`<= `}
            {data.max_amount}
          </td>
          <td>
            {`$ `}
            {parseFloat(data.amount).toFixed(2)}
          </td>
          {props.isMalay && (
            <td>
              {`$ `}
              {(parseFloat(data.amount) - parseFloat(data.other)).toFixed(2)}
            </td>
          )}
          {props.isMalay && (
            <td>
              {`$ `}
              {parseFloat(data.other).toFixed(2)}
            </td>
          )}
          {user.permission === "full" && (
            <td style={{ display: 'flex', gap: '.8rem' }}>
              <CustomButton
                type="secondary"
                size="small"
                width="50px"
                title="Edit"
                onClick={toggle}
              />
              <Tooltip text="Delete" del>
                <CustomButton
                  type={"secondary"}
                  size="small"
                  del
                  width="30px"
                  title={""}
                  onClick={() => {
                    setOpen(true)
                  }
                  }
                  icon={<AiFillDelete />}
                />
              </Tooltip>
            </td>
          )}
           <CommonModal
        handleClose={() => setOpen(false)}
        open={open}
        updateData={()=>handleDeleteManageDeduction(data.id)}
        body={`Are you sure you want to delete Deduction?`}
        heading="Confirm Deduction"
        conformButtonText="Yes"
        cancelButtonText="No"
        onCancel={()=>setOpen(false)} 

      />
        </>
      )}
    </tr>
  );
};

export default ManageDeductionTableRow;
