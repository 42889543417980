import React, { useEffect, useState } from "react";

import { PayrollService } from "../../../_service";
import { useAppSelector } from "../../../_app";
import { Loader, NavigateBack } from "../../../components";
import { Card, Container, Heading, Navlink } from "../Styles";
import { H3Heading } from "../../appSettings/AppSettingsStyles";

const RegularInputItems = () => {
  const employee_id = useAppSelector(
    (state) => state.employee.employee_info.id
  );
  const company_id = useAppSelector((state) => state.user.defaultCompnayId);
  const employee = useAppSelector((state) => state?.employee);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<
    {
      id: string;
      item_id: string;
      item_name: string;
      field_id: string;
      field_name: string;
      field_type: string;
      defualt_value: string;
      item_type_id: string;
      item_type_name: string;
      is_visible_in_payslip: boolean;
      payslip_name: string;
    }[]
  >([]);

  const get = async () => {
    try {
      setLoading(true);
      const res = await PayrollService._getRegularInputItems(
        employee_id,
        company_id,
        employee?.last_date_of_current_month
      );
      setData(res.data);
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    get();
  }, []);

  const itemTypeOrder = ["Income", "Deduction", "Allowance", "Employer contribution", "Reimbursement", "Benefit", "Other"];

  // if (isLoading) return <Loader />;
  return (
    <div style={{ position: "relative" }}>
      <div style={{ display: "flex", gap: 20 }}>
        <NavigateBack />
        <H3Heading>
          Add Regular Item - {employee.currentEmployee.name}
        </H3Heading>
      </div>
      {loading && <Loader />}
      <Container>
        {[...new Set([...itemTypeOrder, ...data.map((p) => p.item_type_name)])].map((item) => {
          return (
            data
              ?.filter(
                (a, i) => data.findIndex((s) => a.item_id === s.item_id) === i
              )
              ?.filter((_: any) => _.item_type_name === item).length > 0 && (
              <Card key={item}>
                <Heading>{item?.replaceAll("_", " ")}</Heading>
                {data
                  ?.filter(
                    (a, i) =>
                      data.findIndex((s) => a.item_id === s.item_id) === i
                  )
                  ?.filter((_: any) => _.item_type_name === item)
                  ?.sort((a:any, b:any)=>a.item_name?.toLowerCase()?.localeCompare(b.item_name?.toLowerCase()))
                  ?.map((item: any) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Navlink
                          to={`/dashboard/RegularInputItemsAddField/${item.item_id}`}
                          state={data?.filter(
                            (_) => _.item_id === item.item_id
                          )}
                          key={item?.item_name}
                        >
                          {item?.item_name}
                        </Navlink>
                        <span>
                          {item.ci_detail?.input_type ? "(Custom)" : ""}
                        </span>
                      </div>
                    );
                  })}
              </Card>
            )
          );
        })}
      </Container>
    </div>
  );
};

export default RegularInputItems;
