import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Notify, Utils } from "../../../utils";
import { PayrollService } from "../../../_service";
import { useAppDispatch, useAppSelector } from "../../../_app";
import { updateCurrentEmployee } from "../../../_features/employee";
import { useNavigate } from "react-router-dom";

// props: showAction:boolean, if you want to show action column or not

const ServicePeriodHistoryInfo = ({showAction = true}) => {
  const [historyData, setHistoryData] = useState([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const employee = useAppSelector((state) => state.employee.employee_info);
  const companyId = useAppSelector((state) => state.user.defaultCompnayId);
  const currentEmployee = useAppSelector(
    (state) => state.employee.currentEmployee
  );

  const getServicePeriodHistory = async () => {
    try {
      const { data, status } = await PayrollService._getServicePeriodHistory({
        employee: employee.id,
        companyId,
      });

      if (status === 200) {
        setHistoryData(data?.data);
      }
    } catch (error: any) {
      Utils.showErrorMessage(error);
    }
  };

  const handleCancelReinstate = async (servicePeriodId: number) => {
    let payload = {
      employee_id: employee.id,
      "service_period_id": servicePeriodId
    };
    try {
      const { status, data } = await PayrollService._cancelReinstateEmployee(payload);
      if (status >= 200 && status < 300) {
        Notify("Reinstated Cancelled", 1);
        dispatch(
          updateCurrentEmployee({
            currentEmployee: {
              ...currentEmployee,
              eos: null,
            },
          })
        )
        navigate("/dashboard/payroll/manage-end-service");
        // navigate("/dashboard/payroll/emp");
        // navigate("/dashboard/employee-payroll")
      }
    } catch (error: any) {
      Utils.showErrorMessage(error);
    }
  };

  useEffect(() => {
    getServicePeriodHistory();
  }, []);

  return (
    <div style={{marginTop: '1rem', marginBottom: '2rem', }}>
      <p
        style={{
          fontSize: "1.75rem",
          color: "#476575",
          fontWeight: 600,
          marginBottom: "0.5rem",
          marginTop: "1.5rem",
        }}
      >
        Service Period History
      </p>

      <Table size="small" aria-label="full width table">
        <TableHead>
          <TableRow>
            <TableCell
              align="left"
              colSpan={1}
              sx={{ fontWeight: "bold", fontSize: "1rem", padding: "0.8rem" }}
            >
              Service Period
            </TableCell>
            <TableCell
              align="left"
              colSpan={1}
              sx={{ fontWeight: "bold", fontSize: "1rem", padding: "0.8rem" }}
            >
              Form Requirements
            </TableCell>
            {showAction && <TableCell
              align="center"
              colSpan={1}
              sx={{ fontWeight: "bold", fontSize: "1rem", padding: "0.8rem" }}
            >
              Actions
            </TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {historyData?.length > 0 &&
            historyData?.map((item: any) => (
              <TableRow>
                <TableCell
                  sx={{ padding: "0.8rem", fontSize: "1rem" }}
                  align="left"
                >{`From ${item?.service_start_date} to ${
                  item?.service_end_date || ` —`
                }`}</TableCell>
                <TableCell
                  sx={{ padding: "0.8rem", fontSize: "1rem" }}
                  align="left"
                >
                  {item?.require_form_ir21
                    ? "Form IR21 Required"
                    : "Form IR21 Not Required"}
                </TableCell>
                {showAction && <TableCell
                  sx={{ padding: "0.8rem", fontSize: "1rem" }}
                  align="center"
                >
                    {/* {` —`} */}
                  {item?.is_reinstate_cancelable ? (
                    <div
                      onClick={() => handleCancelReinstate(item?.id)}
                      style={{
                        width: "fit-content",
                        margin: "auto",
                        padding: "0.25rem 0.75rem",
                        background: "#059BBF",
                        cursor: "pointer",
                        color: "white",
                        fontWeight: 400,
                        borderRadius: "0.25rem",
                      }}
                    >
                      {" "}
                      <span>Cancel Reinstate</span>{" "}
                    </div>
                  ) : (
                    ` —`
                  )}
                </TableCell>}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ServicePeriodHistoryInfo;
