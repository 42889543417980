import { NavLink, Outlet, useLocation } from "react-router-dom";
import { Heading } from "../appSettings/AppSettingsStyles";
import { FilingTabLinkContainer } from "./CPFFilingStyles";
import { useAppSelector } from "../../_app";
import { Utils } from "../../utils";
import CustomTooltip from "../../components/CustomTooltip";

const activeStyle = {
  color: "#212529",
  borderBottom: "3px solid #25b481",
};

const TooltipText = () =>  <div style={{margin: '0.5rem 0.25rem', borderRadius:'8px', padding: "1rem", background: '#F4F6FF',  fontWeight:600 }}>
    <p>{`IR8A amounts are based on finalized payslips`}</p>
  </div>

const Reports = () => {
  const location = useLocation();
  const user = useAppSelector((state) => state.user);
  const routeHeading = {
    "/dashboard/filing/monthly": "Monthly CPF Filing and IRAS",
    "/dashboard/filing/iras": `Tax Certificates: ${
      user?.filing_date ? Utils._dateToShow(user?.filing_date) : ""
    }`,
    "/dashboard/filing/ir21": `Tax Certificates: ${
      user?.filing_date ? Utils._dateToShow(user?.filing_date) : ""
    }`,
  } as any;

  return (
    <div>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: '1rem'
      }}>
        <Heading>{routeHeading[location.pathname] || "" }</Heading>
        {location.pathname?.split("/")?.pop() === "iras" && <CustomTooltip title={<TooltipText />} placement={'bottom'}>
          <img src="/info-outline.svg" style={{marginBottom: '10px'}} />
        </CustomTooltip>}
        </div>
      <FilingTabLinkContainer>
        <NavLink
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
          className="employee-sublink"
          to="monthly"
        >
          CPF Submissions
        </NavLink>
        <NavLink
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
          className="employee-sublink"
          to="iras"
        >
          IRAS Filing (IR8A etc.)
        </NavLink>
        <NavLink
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
          className="employee-sublink"
          to="ir21"
        >
          IRAS Filing (IR21 )
        </NavLink>
      </FilingTabLinkContainer>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default Reports;

export { default as CPFReports } from "./CPFReports";
