import { useEffect, useState } from "react";
import { CustomButton, CustomDateField, NavigateBack } from "../../components";
import { Heading } from "../appSettings/AppSettingsStyles";
import { Utils } from "../../utils";
import { GiroExportText } from "./GiroExportStyles";
import { Link, useLocation } from "react-router-dom";
import { CompanyPayrollService } from "../../_service";
import { useAppSelector } from "../../_app";

const GiroExport = () => {
  const [valueDate, setValueDate] = useState<string>(Utils._date());

  const { state } = useLocation();

  const [giroData, setGiroData] = useState<any>({})

  const user = useAppSelector((state) => state.user);
  const defaultCompanyId = user.defaultCompnayId;

  const getGiroSettings = async () => {
    try {
      const res = await CompanyPayrollService._getGiroSettings(parseInt(defaultCompanyId));
      if (res.status >= 200 && res.status < 300) {
        let data = res?.data?.data;
        setGiroData(data);
      }
    } catch (e:any) {
      Utils.showErrorMessage(e);
    }
  }

  useEffect(()=>{
    getGiroSettings();
  },[])

  console.log('giroData', giroData);

  return (
    <>
      <NavigateBack />
      <Heading>GIRO Export for the month ending {state.payrunDate}</Heading>
      <GiroExportText>
        The following settings will be used for the GIRO export. If they are not
        correct, you change them on the{" "}
        <Link
          to="/dashboard/app-settings/giro-settings"
          style={{ textDecoration: "none" }}
        >
          GIRO Settings{" "}
        </Link>{" "}
        page.
      </GiroExportText>
      <GiroExportText>
        <strong>GIRO File Format: </strong>{giroData?.eft_full_format || ""}
      </GiroExportText>
      <GiroExportText>
        The following information is required for this payment run:
      </GiroExportText>
      <CustomDateField
        label="Value Date"
        date={valueDate}
        setDate={(value: any) => setValueDate(() => value)}
      />
      <CustomButton title="Download" onClick={() => console.log("Check")} />
    </>
  );
};

export default GiroExport;
