import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DullButton from "../../components/buttons/DullButton";
import FormButton from "../../components/buttons/FormButton";
import {
  CustomSelectField,
  CustomTextField,
} from "../../components/customFormField";
import { Constant } from "../../config";
import { Notify } from "../../utils";
import { useAppSelector } from "../../_app";
import { CompanyPayrollService, HelperService } from "../../_service";
import { ButtonContainer } from "./AppSettingsStyles";
import { CustomButton } from "../../components/customButton";

interface EftListResponse {
  item_code: string;
  item_name: string;
}

interface BankListResponse {
  name: string;
}

const GiroSettings = () => {
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user);

  const [eft, setEft] = useState("none");
  const [bank, setBank] = useState("Select a Bank");
  const [accountNumber, setAccountNumber] = useState("");
  const [branchCode, setBranchCode] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [eftList, setEftList] = useState<EftListResponse[]>([]);
  const [bankList, setBankList] = useState<BankListResponse[]>([]);

  const [eftError, setEftError] = useState(false);
  const [bankError, setBankError] = useState(false);
  const [accountError, setAccountError] = useState(false);
  const [branchError, setBranchError] = useState(false);
  const [serviceError, setServiceError] = useState(false);
  const [bankCodeError, setBankCodeError] = useState(false);

  const numberOnlyRegex = new RegExp("^[0-9]+$");

  const changeEft = (value: string) => {
    setEft(value);
    if (value === "none") {
      setEftError(true);
    } else {
      setEftError(false);
    }
  };

  const changeBank = (value: string) => {
    setBank(value);
    if (value === "Select a bank") {
      setBankError(true);
    } else {
      setBankError(false);
    }
  };

  const changeAccountNumber = (value: string) => {
    setAccountNumber(value);
    if (value.length >= 8 && numberOnlyRegex.test(value)) {
      setAccountError(false);
    } else {
      setAccountError(true);
    }
  };
  const changeServiceType = (value: string) => {
    setServiceType(value);
    if (value.length >= 9 && numberOnlyRegex.test(value)) {
      setServiceError(false);
    } else {
      setServiceError(true);
    }
  };
  const changeBankCode = (value: string) => {
    setBankCode(value);
    if (value.length >= 4 && numberOnlyRegex.test(value)) {
      setBankCodeError(false);
    } else {
      setBankCodeError(true);
    }
  };

  const changeBranchCode = (value: string) => {
    setBranchCode(value);
    if (value && value.length <= 10) {
      setBranchError(false);
    } else {
      setBranchError(true);
    }
  };

  const getEftListData = async () => {
    try {
      const { data, status } = await CompanyPayrollService._getEftList();

      if (status === 200) {
        setEftList(() => [
          { item_code: "none", item_name: "Select a EFT Format" },
          ...data.data.list_eft,
        ]);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const getBankList = async () => {
    try {
      const { data, status } = await HelperService._getBankList();

      if (status === 200) {
        setBankList(() => [{ name: "Select a bank" }, ...data]);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const addGiroDetails = async () => {
    if (eft === "none") {
      Notify(Constant.settings.giroSettings.eftFormatError, 0);
      setEftError(true);
      return;
    }

    if (bank === "Select a Bank") {
      Notify(Constant.settings.giroSettings.bankError, 0);
      setBankError(true);
      return;
    }

    if (accountNumber.length < 8 || !numberOnlyRegex.test(accountNumber)) {
      Notify(Constant.settings.giroSettings.accountError, 0);
      setAccountError(true);
      return;
    }

    if (!branchCode || branchCode.length > 10) {
      Notify(Constant.settings.giroSettings.branchError, 0);
      setBranchError(true);
      return;
    }
    if (!serviceType || serviceType.length > 10) {
      Notify(Constant.settings.giroSettings.serviceError, 0);
      setServiceError(true);
      return;
    }
    if (!bankCode || bankCode.length > 10) {
      Notify(Constant.settings.giroSettings.bankCodeError, 0);
      setBankCodeError(true);
      return;
    }

    if (user?.defaultCompnayId) {
      const formData = new FormData();
      formData.append("eft_format", eft);
      formData.append("bank", bank);
      formData.append("account_no", accountNumber);
      formData.append("branch_code", branchCode);
      formData.append("company", user.defaultCompnayId);
      formData.append("service_type",serviceType)
      formData.append("bank_code",bankCode)

      try {
        const { status } = await CompanyPayrollService._addGiroEft(formData);

        if (status === 200) {
          Notify("Employee Giro details Added Successfully", 1);
          navigate("/dashboard/app-settings");
        }
      } catch (error) {
        Notify("Something went wrong, please try again", 0);
        setAccountNumber("");
        setBranchCode("");
        setBankCode("");
        setServiceType("");
      }
    }
  };

  const getGiroSettings = async () => {
    try {
      if (user?.defaultCompnayId) {
        const { status, data } = await CompanyPayrollService._getGiroSettings(
          parseInt(user?.defaultCompnayId),
        );
        if (status === 200) {
          if (data.data?.id) {
            setEft(data.data?.eft_format || "none");
            setBank(data.data?.bank || "Select a Bank");
            setAccountNumber(data.data?.account_no || "");
            setBranchCode(data.data?.branch_code || "");
            setBankCode(data.data?.bank_code || "");
            setServiceType(data.data?.service_type || "");
          }
        }
      }
    } catch (error) {
      Notify("Something went wrong, please try again", 1);
      setEft("none");
      setBank("Select a Bank");
      setAccountNumber("");
      setBranchCode("");
      setBankCode("")
      setServiceType("")
    }
  };

  useEffect(() => {
    if (user?.defaultCompnayId) {
      getEftListData();
      getBankList();
      getGiroSettings();
    }
  }, []);

  return (
    <div>
      {user?.defaultCompnayId ? (
        <>
          <CustomSelectField
            title={Constant.settings.giroSettings.eftFormat}
            value={eft}
            onChangeOption={(value) => changeEft(value)}
            option={eftList.map((eftData) => ({
              key: eftData.item_name,
              value: eftData.item_code,
            }))}
            error={
              eftError
                ? Constant.settings.giroSettings.eftFormatError
                : undefined
            }
          />
          <CustomTextField
            title={Constant.settings.giroSettings.servicetype}
            value={serviceType}
            onChangeText={changeServiceType}
            error={
              serviceError
                ? Constant.settings.giroSettings.serviceError
                : undefined
            }
          />
          <h2 style={{ color: "#204ca0", fontSize: "1rem", margin: "10px 0"}}>Bank account details</h2>
          <CustomSelectField
            title={Constant.settings.giroSettings.bank}
            value={bank}
            onChangeOption={(value) => changeBank(value)}
            option={bankList.map((bankData) => ({
              key: bankData.name,
              value: bankData.name,
            }))}
            error={
              bankError ? Constant.settings.giroSettings.bankError : undefined
            }
          />
          <CustomTextField
            title={Constant.settings.giroSettings.bankcode}
            value={bankCode}
            onChangeText={changeBankCode}
            error={
              bankCodeError
                ? Constant.settings.giroSettings.bankCodeError
                : undefined
            }
          />
             <CustomTextField
            title={Constant.settings.giroSettings.branchCode}
            value={branchCode}
            onChangeText={changeBranchCode}
            error={
              branchError
                ? Constant.settings.giroSettings.branchError
                : undefined
            }
          />
          <CustomTextField
            title={Constant.settings.giroSettings.accountNumber}
            value={accountNumber}
            onChangeText={changeAccountNumber}
            error={
              accountError
                ? Constant.settings.giroSettings.accountError
                : undefined
            }
          />
       
          <ButtonContainer>
            <CustomButton
              title="Cancel"
              type="secondary"
              onClick={() => navigate("/dashboard/app-settings")}
            />
            <CustomButton title="Save" onClick={addGiroDetails} />
          </ButtonContainer>
        </>
      ) : (
        <p>{Constant.settings.giroSettings.noCompanyError}</p>
      )}
    </div>
  );
};

export default GiroSettings;
