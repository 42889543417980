import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AuthHeader } from "../../components/header";
import { useAppSelector } from "../../_app";
import { AppSettingService } from "../../_service";
import { CustomButton, CustomeCkeckboxField, Loader } from "../../components";
import cloneDeep from "lodash.clonedeep";
import { Notify, Utils } from "../../utils";
import CustomLoader from "../../components/CustomLoader";
import { LoaderContainer } from "../../CommonStyles";
import {
  MultiSelectButton,
  ReportHorizontalSeparator,
  ReportSectionHeading,
} from "../reports/ReportFilterPageStyles";
import { BsPlusCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import EmployeeHeader from "../dashboard/manage-employee/employeeHeader";
import { Heading } from "./AppSettingsStyles";
import { IoIosArrowDroprightCircle, IoIosArrowForward } from "react-icons/io";
import { MdDoubleArrow } from "react-icons/md";

const PayItemsListing = () => {
  const user = useAppSelector((state) => state.user);
  const defaultCompanyId = user.defaultCompnayId;
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [settingsData, setSettingsData] = useState<any>(null);
  const [statusData, setStatusData] = useState<any>({});

  //   ---- new api ----
  const getGlobalPayItemsListing = async () => {
    setIsLoading(() => true);
    try {
      const res = await AppSettingService._getCompanyPayItemsListing(defaultCompanyId);
      if (res.status >= 200 && res.status < 300) {
        let data = res?.data?.data;
        setSettingsData(data);
      }
    } catch (e: any) {
      Utils.showErrorMessage(e);
    }
    setIsLoading(() => false);
  };

  useEffect(() => {
    getGlobalPayItemsListing();
  }, []);

  // if (isLoading) {
  //   return (
  //     <div>
  //       <LoaderContainer>
  //         <Loader />
  //       </LoaderContainer>
  //     </div>
  //   );
  // }

  const itemOrder = [
    "income",
    "allowance",
    "summary",
    "deductions",
    "reimbursement",
    "other",
    "employer_contribution",
    "quantity",
  ];

  const sortedEntriesBuiltIn = Object.entries(
    settingsData?.built_in_items || {}
  ).sort(([key1], [key2]) => {
    // const order = [...itemOrder];
    const order = [
      ...new Set([
        ...itemOrder,
        ...Object.entries(settingsData?.built_in_items || {}).map(
          (x: any) => x[0]
        ),
      ]),
    ];
    console.log("finalOrder", order);
    return order.indexOf(key1) - order.indexOf(key2);
  });

  const sortedEntriesCustom = Object.entries(
    settingsData?.custom_items || {}
  ).sort(([key1], [key2]) => {
    // const order = [...itemOrder];
    const order = [
      ...new Set([
        ...itemOrder,
        ...Object.entries(settingsData?.built_in_items || {}).map(
          (x: any) => x[0]
        ),
      ]),
    ];
    console.log("finalOrder", order);
    return order.indexOf(key1) - order.indexOf(key2);
  });

  console.log("Custom Sort", sortedEntriesCustom);

  return (
    <div>
      <div className="hero_header">
        <h2 className="employee-header-h2">Pay Items</h2>
      </div>
      <EmployeeHeader />
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            gap: "2rem",
            width: "90%",
          }}
        >
          {/* <p
          style={{
            fontSize: "1.75rem",
            color: "rgb(71, 101, 117)",
            fontWeight: 600,
            width: "100%",
            borderBottom: "1px solid black",
            paddingBottom: "0.5rem",
            marginBottom: "1rem",
          }}
        >
          {"Global Custom Reports"}
        </p> */}

        {/* -------------- Custom Item Listing ------------ */}
        <div
            style={{
              marginTop: "0.75rem",
              width: "90%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.25rem",
                minHeight: "3.125rem",
                padding: "0.75rem 0.25rem",
                borderRadius: "0.2rem",
                fontSize: "1.25rem",
                backgroundColor: "#4a5e71",
                color: "#f8f9fa",
                marginBottom: "1rem",
                fontWeight: 700,
                width: "100%",
              }}
            >
              <IoIosArrowForward strokeWidth={"1.25rem"} />
              <p>Custom Items</p>
            </div>
            {settingsData &&
              sortedEntriesCustom?.length > 0 &&
              sortedEntriesCustom?.map((item: any) => (
                <div
                  style={{
                    marginBottom: "1rem",
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "0.25rem",
                    border: "1px solid #aaaaaa",
                    backgroundColor: "#F3F3F3",
                  }}
                >
                  {/* <p
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: 700,
                    textTransform: "capitalize",
                    marginBottom: "0.5rem",
                  }}
                >
                  {item[0].replace("_", " ")}
                </p> */}
                  {/* <ReportSectionHeading>
                  {item[0].replace("_", " ")}                  
                </ReportSectionHeading> */}

                  <div
                    style={{
                      display: "flex",
                      gap: 20,
                      justifyItems: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Heading>
                      {item[0] === "allowance"
                        ? "Allowances"
                        : item[0] === "summary"
                        ? "Gross Pay"
                        : item[0].replace("_", " ")}{" "}
                    </Heading>
                    {/* <MultiSelectButton onClick={() => handleSelectAllForSection(item[1])}>
                    All
                  </MultiSelectButton>
                  <MultiSelectButton onClick={() => handleSelectNoneForSection(item[1])}>
                    None
                  </MultiSelectButton> */}
                    {/* <p onClick={() => {
                      navigate("/dashboard/add-item");
                    }}
                    style={{
                      width: 'fit-content',
                      padding: "0.25rem 0.75rem",
                      cursor: "pointer",
                      border: '1px solid #03b381',
                      borderRadius: "0.25rem",
                      color: '#03b381',
                      fontWeight: 500,
                      fontSize: '1rem',
                      display: 'flex',
                      alignItems: 'center',
                      gap: "0.5rem",
                      marginLeft: 'auto'
                    }}>
                      <BsPlusCircleFill />
                        Add
                  </p> */}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection:'column',
                      flexWrap: 'wrap',
                      gap: "0.625rem",
                      marginTop: "0.5rem",
                      width: "100%",
                      // maxHeight: '40vh',
                      justifyContent: 'start',
                      alignItems: 'start'
                    }}
                  >
                    {item[1]?.map((x: any) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "1rem",
                          alignItems: "center",
                          width: "fit-content",
                          // marginBottom: "0.25rem",
                          color: "#333333",
                        }}
                      >
                        <p>{Utils.formateStringWithUnderscore(x.name)}</p>
                        {/* <input
                        type="checkbox"
                        checked={statusData[x.code]}
                        onChange={(e) =>
                          handleCheckbox(e.target.checked, x.code)
                        }
                        disabled={disabledList?.includes(x.code)}
                        style={{
                          width: "1rem",
                          height: "1rem",
                        }}
                      /> */}
                      </div>
                    ))}
                  </div>

                  {/* <ReportHorizontalSeparator /> */}
                </div>
              ))}
          </div>

            {/* Built in items */}
          <div
            style={{
              marginTop: "0.75rem",
              width: "90%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.25rem",
                minHeight: "3.125rem",
                padding: "0.75rem 0.25rem",
                borderRadius: "0.2rem",
                fontSize: "1.25rem",
                backgroundColor: "#4a5e71",
                color: "#f8f9fa",
                marginBottom: "1rem",
                fontWeight: 700,
                width: "100%",
              }}
            >
              <IoIosArrowForward strokeWidth={"1.25rem"} />
              <p>Built-in Items</p>
            </div>
            {settingsData &&
              sortedEntriesBuiltIn?.length > 0 &&
              sortedEntriesBuiltIn?.map((item: any) => (
                <div
                  style={{
                    marginBottom: "1rem",
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "0.25rem",
                    border: "1px solid #aaaaaa",
                    backgroundColor: "#F3F3F3",
                  }}
                >
                  {/* <p
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: 700,
                    textTransform: "capitalize",
                    marginBottom: "0.5rem",
                  }}
                >
                  {item[0].replace("_", " ")}
                </p> */}
                  {/* <ReportSectionHeading>
                  {item[0].replace("_", " ")}                  
                </ReportSectionHeading> */}

                  <div
                    style={{
                      display: "flex",
                      gap: 20,
                      justifyItems: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Heading>
                      {item[0] === "allowance"
                        ? "Allowances"
                        : item[0] === "summary"
                        ? "Gross Pay"
                        : item[0].replace("_", " ")}{" "}
                    </Heading>
                    {/* <MultiSelectButton onClick={() => handleSelectAllForSection(item[1])}>
                    All
                  </MultiSelectButton>
                  <MultiSelectButton onClick={() => handleSelectNoneForSection(item[1])}>
                    None
                  </MultiSelectButton> */}
                    {/* <p onClick={() => {
                      navigate("/dashboard/add-item");
                    }}
                    style={{
                      width: 'fit-content',
                      padding: "0.25rem 0.75rem",
                      cursor: "pointer",
                      border: '1px solid #03b381',
                      borderRadius: "0.25rem",
                      color: '#03b381',
                      fontWeight: 500,
                      fontSize: '1rem',
                      display: 'flex',
                      alignItems: 'center',
                      gap: "0.5rem",
                      marginLeft: 'auto'
                    }}>
                      <BsPlusCircleFill />
                        Add
                  </p> */}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection:'column',
                      flexWrap: 'wrap',
                      gap: "0.625rem",
                      marginTop: "0.5rem",
                      width: "100%",
                      // maxHeight: '40vh',
                      justifyContent: 'start',
                      alignItems: 'start'
                    }}
                  >
                    {item[1]?.map((x: any) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "1rem",
                          alignItems: "center",
                          width: "fit-content",
                          // marginBottom: "0.25rem",
                          color: "#333333",
                        }}
                      >
                        <p>{Utils.formateStringWithUnderscore(x.name)}</p>
                        {/* <input
                        type="checkbox"
                        checked={statusData[x.code]}
                        onChange={(e) =>
                          handleCheckbox(e.target.checked, x.code)
                        }
                        disabled={disabledList?.includes(x.code)}
                        style={{
                          width: "1rem",
                          height: "1rem",
                        }}
                      /> */}
                      </div>
                    ))}
                  </div>

                  {/* <ReportHorizontalSeparator /> */}
                </div>
              ))}
          </div>

          
        </div>
      )}
    </div>
  );
};

export default PayItemsListing;
