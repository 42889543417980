import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import {
  CustomButton,
  CustomDateField,
  NavigateBack,
} from "../../../components";
import { Heading, PageHeading } from "../Styles";
import { H3Heading } from "../../appSettings/AppSettingsStyles";
import { PayrollService } from "../../../_service";
import { Notify } from "../../../utils";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../_app";
import { updateCurrentEmployee } from "../../../_features/employee";
import { Constant } from "../../../config";
import ServicePeriodHistoryInfo from "./ServicePeriodHistoryInfo";

const ReinstateEmployee = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const employee = useAppSelector((state) => state.employee.employee_info);
  const companyId = useAppSelector((state) => state.user.defaultCompnayId);
  const currentEmployee = useAppSelector(
    (state) => state.employee.currentEmployee
  );

  const [showBanner, setShowBanner] = useState({
    isVisible: false,
    errorMessage: "",
  });

  const [reinstateDate, setReinstateDate] = useState<string>("");

  const handleReinstate = async () => {
    let payload = {
      employee_id: employee.id,
      date: reinstateDate,
    };
    try {
      const { status } = await PayrollService._reinstateEmployee(payload);
      if (status >= 200 && status < 300) {
        Notify("Employee Reinstated", 1);
        dispatch(
          updateCurrentEmployee({
            currentEmployee: {
              ...currentEmployee,
              eos: null,
            },
          })
        );
        navigate("/dashboard/payroll/emp");
        // navigate("/dashboard/employee-payroll")
      }
    } catch (error: any) {
      if (error.response.data?.message) {
        setShowBanner((prev) => {
          return {
            ...prev,
            isVisible: true,
            errorMessage: error.response.data?.message,
          };
        });
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }
  };

  console.log("emp", employee, reinstateDate);

  return (
    <div>
      {showBanner.isVisible && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "80%",
            padding: "1rem",
            borderRadius: "0.5rem",
            textTransform: "uppercase",
            background: "#ff6665",
            color: "#ffffff",
            fontWeight: 800,
            border: "1px solid ff6665",
            marginBottom: "1rem",
          }}
        >
          <p>{`${showBanner.errorMessage}`}</p>
          <IoMdClose
            size={24}
            onClick={() =>
              setShowBanner((prev: any) => {
                return { ...prev, isVisible: false, errorMessage: "" };
              })
            }
            style={{
              cursor: "pointer",
            }}
          />
        </div>
      )}

      {/* bottom part */}

      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <NavigateBack />
        <PageHeading>{`Reinstate Employee - ${currentEmployee?.name}`}</PageHeading>
      </div>
      <div style={{ margin: "40px 16px" }}>
        <CustomDateField
          label="From Date:"
          date={reinstateDate || null}
          setDate={setReinstateDate}
        />
      </div>

      <ServicePeriodHistoryInfo />

      <div style={{
        display:'flex',
        alignItems: "center"
      }}>
        <CustomButton
            type="secondary"
            title="Cancel"
            onClick={() => {
              navigate(-1);
            }}
          />
      <CustomButton title="Save" onClick={handleReinstate} />
      </div>
    </div>
  );
};

export default ReinstateEmployee;
