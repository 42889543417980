function getBaseURL() {
  // const urlEndPoint = "v1/api/";
  // const currentURL =
  //   window.location.protocol +
  //   "//" +
  //   window.location.hostname +
  //   (window.location.port && ":" + window.location.port) +
  //   "/";
  // if (currentURL === "http://localhost:3000/") {
  //   return process.env.REACT_APP_BASE_URL;
  // }
  // return currentURL + urlEndPoint;
  return process.env.REACT_APP_BASE_URL;
}

const URL = {
  LOGIN: getBaseURL() + "auth/login/",
  REFRESH_TOKEN: getBaseURL() + "auth/refresh/",
  PASSWORD_RESET: getBaseURL() + "password_reset/",
  VALIDATE_TOKEN: getBaseURL() + "password_reset/validate_token/?token=",
  CHANGE_PASSWORD: getBaseURL() + "password_reset/confirm/",
  USER_CHANGE_PASSWORD: getBaseURL() + "auth/change-password/",
  PASSWORD_SET: getBaseURL() + "auth/user/set_password/",
  LOGOUT_REGISTER: getBaseURL() + "auth/user-activity/logout_register/",

  GET_ALL_COMPANIES: getBaseURL() + "company/all/",
  ADD_COMPANY: getBaseURL() + "company/all/",
  GET_COMPANY: getBaseURL() + "company/",
  UPDATE_COMPANY: getBaseURL() + "company/",
  DELETE_COMPANY: getBaseURL() + "company/",
  
  ACTIVATE_COMPANY: getBaseURL() + "company_api/",
  DEACTIVATE_COMPANY: getBaseURL() + "company_api/",
  
  GET_ORG_ID_TYPE_LIST: getBaseURL() + "company_api/org_id_type_list/",
  FILE_DETAILS: getBaseURL() + "company_api/create_filling_details/",
  UPDATE_FILING_DETAILS: getBaseURL() + "company_api/",
  GET_FILING_DETAILS: getBaseURL() + "company_api/filling_details_by_company/",
  ADD_EMPLOYEE_NUMBER: getBaseURL() + "company_api/update_setting/",
  GET_EMPLOYEE_NUMBER: getBaseURL() + "company_api/setting_by_code_company/",
  CREATE_PAY_FREQUENCY: getBaseURL() + "company_api/create_pay_frequency/",
  GET_PAY_FREQUENCY: getBaseURL() + "company_api/pay_frequency_by_company/",
  GET_EMPLOYEE_ID_FROM_COMPANY: getBaseURL() + "auth/user/get_emp_id?company=",

  MAKE_DEFAULT_COMPANY: getBaseURL() + "company/make-company-default/",
  UPDATE_DEFAULT_COMPANY: getBaseURL() + "company/make-company-default/",

  GET_DEDUCTION_CATEGORY: getBaseURL() + "company/deduction/category/",
  GET_DEDUCTION_DATA_BY_CATEGORY:
    getBaseURL() + "company/deduction/data?deduction_category_id=",
  UPDATE_DEDUCTION_DATA: getBaseURL() + "company/deduction/data/",

  GET_SDL: getBaseURL() + "company/sdl/",
  UPDATE_SDL: getBaseURL() + "company/sdl/",

  GET_CPF_CALCULATOR_CATEGORY: getBaseURL() + "cpf/calculator/category/",
  GET_CPF_CALCULATOR_DATA_BY_CATEGORY:
    getBaseURL() + "cpf/calculator/formula/?category_id=",
  UPDATE_CPF_CALCULATOR_DATA_BY_CATEGORY:
    getBaseURL() + "cpf/calculator/formula/",
  GET_CPF_POLICY: getBaseURL() + "cpf/calculator/category/policy",
  GET_CPF_LIMIT: getBaseURL() + "cpf/calculator/limit/",
  GET_AW_LIMIT: getBaseURL() + "cpf/calculator/awlimit/",

  GET_CPF_CALCULATOR: getBaseURL() + "cpf/calculator/",
  GET_RACE_HELPER: getBaseURL() + "payroll/info/race/",
  GET_RELIGION_HELPER: getBaseURL() + "payroll/info/religion/",
  GET_PAYMENT_METHODS_HELPER: getBaseURL() + "payroll/info/payment-methods/",
  GET_LEGAL_STATUS_HELPER: getBaseURL() + "payroll/info/legal-status/",
  GET_COUNTRY_HELPER: getBaseURL() + "payroll/info/country/",
  GET_PAYSLIP_DATA: getBaseURL() + "payroll/payslip/",
  GET_EMPLOYEE_LIST: getBaseURL() + "payroll/employee/add/?company_id=",
  GET_EMPLOYEE_PAYROLL_LIST:
    getBaseURL() + "payroll/employee/payroll/?company_id=",
  SEND_FOR_APPROVAL: getBaseURL() + "payroll/employee_payslip/send_approval/",

  GET_BANK_LIST: getBaseURL() + "payroll/info/bank/",
  GET_ADDRESS_TYPES: getBaseURL() + "payroll/info/address/",
  ADD_NEW_EMPLOYEE: getBaseURL() + "payroll/employee/add/",
  UPDATE_NEW_EMPLOYEE: getBaseURL() + "payroll/employee/",
  ADD_REGULAR_HOURS: getBaseURL() + "payroll/working-hours/",
  GET_REGULAR_HOURS_LOG:
    getBaseURL() + "payroll/recent_changes/?action_type=regular_input",
  GET_PAYSLIP_LOG: getBaseURL() + "payroll/recent_changes/?action_type=payslip",
  VALIDATE_EMP_DATA: getBaseURL() + "payroll/employee/data/",
  GET_PAYROLL_DASHBOARD: getBaseURL() + "payroll/dashboard/?employee_id=",
  GET_BASIC_SALARY: getBaseURL() + "payroll/basic-salary/?employee_id=",
  UPDATE_BASIC_SALARY: getBaseURL() + "payroll/basic-salary/",
  GET_EMPLOYEE: getBaseURL() + "payroll/employee/",
  UPDATE_WORKING_HOURS: getBaseURL() + "payroll/working-hours/",
  GET_WORKING_HOURS: getBaseURL() + "payroll/working-hours/?employee_id=",
  GET_PAYSLIP_CALCULATION_DATA:
    getBaseURL() + "payroll/payslip-calculation-list/",
  UPDATE_PAYSLIP_CALCULATION_DATA:
    getBaseURL() + "payroll/payslip-calculation/",
  RESET_PAYROLL: getBaseURL() + "payroll/employee_payslip/reset/",

  UPDATE_NOTES: getBaseURL() + "payroll/employee_payslip/",
  GET_NOTES: getBaseURL() + "payroll/employee_payslip/",
  UPDATE_FINALIZE_STATUS: getBaseURL() + "payroll/employee_payslip/",

  GET_END_SERVICE_REASON: getBaseURL() + "payroll/employee-end-service-reasons",
  POST_END_SERVICE: getBaseURL() + "payroll/employee-end-service/?",
  GET_SERVICE_PERIOD_HISTORY: getBaseURL() + "payroll/employee-service-period/?",
  UNDO_TERMINATION: getBaseURL() + "payroll/employee-end-service/?",
  REINSTATE_EMPLOYEE: getBaseURL() + "payroll/employee-service-period/reinstate/",
  CANCEL_REINSTATE_EMPLOYEE: getBaseURL() + "payroll/employee-service-period/cancel_reinstate/",

  GET_REGULAR_INPUT_ITEM: getBaseURL() + "payroll/regular-input-items?",
  UPDATE_REGULAR_INPUT_ITEM: getBaseURL() + "payroll/regular-input-calculations?",
  GET_PAYRUN: getBaseURL() + "payroll/employee_payslip/payrun_details/",
  GET_PAYRUN_BY_COMPANY:
    getBaseURL() + "payroll/employee_payslip/payrun_by_company/?company=",
  GET_ACCOUNTING_INFO_BY_COMPANY:
    getBaseURL() + "payroll/employee_payslip/accounting_info/?company_id=",
  GET_ALL_ACCOUNTING_INFO_BY_COMPANY:
    getBaseURL() + "payroll/employee_payslip/all_accounting_info/?company_id=",
  BULK_PAYSLIP_FINALIZE:
    getBaseURL() + "payroll/employee_payslip/bulk_update_finalize_status/",
  CREATE_PAYRUN: getBaseURL() + "payroll/employee_payslip/create_payrun/",



  GET_CUSTOM_INPUT: getBaseURL() + "payroll/custom_input/input_category_list/",
  GET_CUSTOM_INPUT_OPTION:
    getBaseURL() + "payroll/custom_input/input_type_list/",
  GET_CUSTOM_INPUT_IR8A_LIST:
    getBaseURL() +
    "payroll/custom_input/ir8a_list_by_category/?input_category=",
  GET_CUSTOM_INPUT_CPF_LIST:
    getBaseURL() + "payroll/custom_input/cpf_type_list/",
  ADD_CUSTOM_INPUT: getBaseURL() + "payroll/custom_input/",

  GET_USER_ROLES: getBaseURL() + "auth/group/",
  ADD_USER_ROLE: getBaseURL() + "auth/group/",
  GET_ALL_PERMISSIONS: getBaseURL() + "auth/permission/",
  GET_ALL_SECTIONS: getBaseURL() + "auth/management-sections/",
  EDIT_GROUPS: getBaseURL() + "auth/management-sections/set_permission/",
  GET_USER_PERMISSIONS: getBaseURL() + "auth/user-management/",
  
  ADD_MANAGE_DEDUCTION : getBaseURL()+ "company/deduction/data/",  // ADDED NEW API FOR POST
  DELETE_MANAGE_DEDUCTION :getBaseURL()+ "company/deduction/data/",  // delete md
 
  GET_ALL_USERS: getBaseURL() + "auth/user/",
  DEACTIVATE_USER: getBaseURL() + "auth/deactivate/",
  REACTIVATE_USER: getBaseURL() + "auth/activate/",

  CHECK_USER_EXIST: getBaseURL() + "auth/user/check_user_exists/?email=",

  GET_EFT_LIST: getBaseURL() + "company_api/dropdown_eft_list/",
  GET_GIRO_DETAILS: getBaseURL() + "company_api/giro/",
  ADD_EFT: getBaseURL() + "company_api/create_giro/",
  GET_CPF_OPTIONS: getBaseURL() + "company_api/dropdown_pay_calculation/",
  GET_COMPANY_SETTINGS: getBaseURL() + "company_api/setting_by_code_company/",

  GET_LEAVE_TYPES: getBaseURL() + "company/leave/",
  GET_CYCLE_START_DATE_OPTIONS:
    getBaseURL() + "company/leave/cycle_start_date_list/",
  GET_ALL_ENTITLEMENT_POLICIES:
    getBaseURL() + "company/leave/entitlement-policy/",
  GET_ALL_ACTIVE_ENTITLEMENT_POLICIES:
    getBaseURL() + "company/leave/active-entitlement-policy/",
  GET_LEAVE_OVERVIEW: getBaseURL() + "company/leave/overview/?",
  GET_LEAVE_ADJUSTMENT_ACTIVITY:
    getBaseURL() + "company/leave/adjustment-activity/",
  GET_LEAVE_ACTIVITY: getBaseURL() + "company/leave/leave-activity/",
  LEAVE_RECORD_TAKEN: getBaseURL() + "company/leave/record-taken/",
  LEAVE_RECORD_TAKEN_BALANCE:
    getBaseURL() + "company/leave/record-taken/leave_balance?",
  GET_UNAPPROVED_LEAVES:
    getBaseURL() + "company/leave/record-taken/unapproved/?",
  GET_RECENT_ACTIVITY:
    getBaseURL() + "company/leave/record-taken/recent_activity/?",

  GET_PAYSLIP_REPORTS: getBaseURL() + "payroll/report/",

  GET_MONTHLY_CPF: getBaseURL() + "cpf/cpf-report-list/",
  UPDATE_CPF_STATUS: getBaseURL() + "cpf/cpf-report/",
  ADD_CPF_PENALTY: getBaseURL() + "cpf/cpf-input/",

  GET_ALL_IR8A_FILING: getBaseURL() + "company/iras-filling/",
  GET_PDF_IR8A_FILING: getBaseURL() + "company/iras-filling/view_pdf_manual_ir8a/",
  GET_PDF_IR21_FILING: getBaseURL() + "company/ir21-filling/",
  GET_ALL_IR21_FILING: getBaseURL() + "company/ir21-filling/",
  EXPORT_MANUAL_IR8A_FILE: getBaseURL() + "payroll/manual_payslip/export/",
  GET_MANUAL_IR8A_DATA: getBaseURL() + "payroll/manual_payslip/",

  GET_ALL_TEMPLATES: getBaseURL() + "templates/",

  GET_REQUEST_TYPES: getBaseURL() + "dashboard/request_types",
  SUBMIT_REQUEST: getBaseURL() + "dashboard/submit_request/",
  REQUEST_LIST: getBaseURL() + "dashboard/request_submissions/?stage=",
  COMPLETED_LIST: getBaseURL() + "dashboard/request_submissions/?status=",
  START_REQUEST: getBaseURL() + "dashboard/claim_request/?request_id=",
  MOVE_REQUEST: getBaseURL() + "dashboard/move_stage/?request_id=",
  COMPLETE_REQUEST: getBaseURL() + "dashboard/update_status/?request_id=",

  BULK_APPROVE_IRAS: getBaseURL() + "payroll/manual_payslip/bulk_approve/",
  GET_CPF_PDF: getBaseURL() + "cpf/cpf-input/",

  CATEGORY_LIST: getBaseURL() + "reports/categories/",
  DOWNLOAD_LEAVE_REPORT: getBaseURL() + "reports/leave_report_export/?company=",
  DOWNLOAD_LEAVE_DAYS_REPORT:
    getBaseURL() + "reports/leave_days_report_export/?company=",
  DOWNLOAD_LEAVE_EXPIRY_REPORT:
    getBaseURL() + "reports/expired_leave_export/?company=",
  GET_BASIC_INFO_DATA: getBaseURL() + "payslip_reports/basic_info/",
  GET_PAYSLIP_EXPORT_DATA: getBaseURL() + "payslip_reports/payslip_export/",
  GET_TRANSACTION_FILTERS:
    getBaseURL() + "payslip_reports/transaction_filters/",
  GET_PAYSLIP_TRANSACTION_DATA:
    getBaseURL() + "payslip_reports/transaction_report/",

  GET_PENDING_APPROVAL_LIST:
    getBaseURL() + "payroll/employee_payslip/pending_approval_list/?company=",
  APPROVE_PAYSLIP: getBaseURL() + "payroll/employee_payslip/bulk_approval/",

  GET_SELF_SERVICE_EMPLOYEE_LIST:
    getBaseURL() + "company/self-service/employee_list/?company=",
  POST_EMPLOYEE_USER: getBaseURL() + "company/self-service/employee_user/",
  GET_SELF_SERVICE_SETTINGS: getBaseURL() + "company/self-service/",
  GET_INFO_UPDATE_REQUESTS:
    getBaseURL() + "dashboard/fetch_update_leave_records/?",
  POST_INFO_UPDATE_REQUESTS:
    getBaseURL() + "dashboard/fetch_update_leave_records/?",

  GET_EMPLOYEE_PAYSLIPS: getBaseURL() + "payroll/employee_payslip/my_payslips/",
  GET_ANNUAL_BONUS: getBaseURL() + "company/annual-bonus/?company=",

  // no longer in use
  GET_ANNUAL_INCREMENT: getBaseURL() + "company/annual-increment/?company_id=",
  
  
  GET_ANNUAL_INCREMENT_INITIAL_DATA: getBaseURL() + "company/annual-increment/get_initial_data/?company=",
  GET_ANNUAL_INCREMENT_EDIT_DATA: getBaseURL() + "company/annual-increment/",
  GET_ANNUAL_BONUS_INITIAL_DATA: getBaseURL() + "company/annual-bonus/get_initial_data/?company=",
  GET_ANNUAL_BONUS_EDIT_DATA: getBaseURL() + "company/annual-bonus/",
  ANNUAL_INCREMENT_LISTING: getBaseURL() + "company/annual-increment/listing_increments/?company_id=",
  ANNUAL_BONUS_LISTING: getBaseURL() + "company/annual-bonus/listing_bonus/?company_id=",
  DELETE_ANNUAL_INCREMENT: getBaseURL() + "company/annual-increment/",
  DELETE_ANNUAL_BONUS: getBaseURL() + "company/annual-bonus/",
  SAVE_ANNUAL_BONUS: getBaseURL() + "company/annual-bonus/bulk_create_or_update/",
  SAVE_ANNUAL_INCREMENT: getBaseURL() + "company/annual-increment/bulk_create_or_update/",
  GET_EMPLOYEE_CHANGE_LOGS: getBaseURL() + "change-trail-log/",
  GET_EMPLOYEE_PAYSLIP_HISTORY: getBaseURL() + "payroll/employee_payslip/payslip_history_chart/?company_id=",

  GET_CUSTOM_REPORT_SETTINGS: getBaseURL() + "custom_reports/?company_id=",

  SAVE_CUSTOM_REPORT_SETTINGS: getBaseURL() + "custom_reports/create_or_update/",
  GET_EXTERNAL_COMPANY_LISTING:getBaseURL()+ "external-dashboard/submit_request/",
  GET_COMPANY_NAME:getBaseURL() + `external-dashboard/company-list/?starts_with=`,

  GET_CUSTOM_REPORT: getBaseURL() + `custom_reports/get_report_data/`,

  CHECK_CPF_RETURN_STATUS: getBaseURL() + `payroll/employee_payslip/check_cpf_return/?company_id=`,
  
  REVERSE_APPROVAL: getBaseURL() + `payroll/employee_payslip/reverse_approval/?company_id=`,


  
  GET_SERVICE_PERIOD_INFO: getBaseURL()+"payroll/employee-service-period/get_period/",

  EDIT_SERVICE_PERIOD_INFO:getBaseURL()+"payroll/employee-service-period/",

  SERVICE_CODES: getBaseURL()+"customer/service/",

  MANAGE_SERVICE: getBaseURL() + "customer/service/manage/?action=",

  GET_INVOICE_LIST: getBaseURL() + "customer/invoicing/?company_id=",

  GET_SEARCH_DATA: getBaseURL() + "customer/invoicing/?search=",

  RESEND_INVOICE: getBaseURL() + "customer/invoicing/",

  SAVE_INVOICE: getBaseURL() + "customer/invoicing/?action=",

  DOWNLOAD_INVOICE: getBaseURL() + "customer/invoicing/",

  VIEW_INVOICE: getBaseURL() + "customer/invoicing/",

  RECONCILE_INVOICE: getBaseURL() + "customer/invoicing/bulk_reconcile/",
  
  GET_INVOICE_DATA: getBaseURL() + "customer/invoicing/get_invoice_data/?company_id=",
  
  GET_GLOBAL_INVOICE_DATA: getBaseURL() + "customer/invoicing/",

  GET_GLOBAL_PAY_ITEMS: getBaseURL() + "payroll/payitems/?level=global",
  GET_COMPANY_PAY_ITEMS: getBaseURL() + "payroll/payitems/?level=company",

  GET_SPC_DATA: getBaseURL() + "customer/service-provider-company/",

  ADD_SPC_DATA:getBaseURL()+ "customer/service-provider-company/",

  EDIT_SPC_DATA:getBaseURL()+ "customer/service-provider-company/",
 
  SPC_CODE: getBaseURL()+ "customer/service-provider-company/",

  VOID_INVOICE: getBaseURL() + "customer/invoicing/void/"
};

export default URL;
