import React from "react";
import { AppSettingService } from "../../../_service";
import { useQuery } from "react-query";
import { NavLink, useNavigate } from "react-router-dom";

import "./style.css";
import { CustomButton } from "../../../components/customButton";
import { Loader } from "../../../components";
import { MdDragIndicator } from "react-icons/md";

const AddCustomItems = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery("_repoData", () =>
    AppSettingService._getCustomInput(),
  );

  return (
    <div>
      {isLoading && <Loader />}
      <h1 style={{ color: "#476575", marginBottom: "1rem" }}>
        Add New Custom Item
      </h1>
      <p style={{marginBottom: '0.5rem'}}>Select type of custom item to add:</p>
      <ul
        style={{ marginLeft: "2rem", flexDirection: "column" }}
        className="payslip-container"
      >
        {(data?.data?.data?.input_items || []).map((item: any) => {
          return (
            item.item_code !== "benefit" && (
              <li
                key={item?.item_code}
                style={{ margin: ".1rem", fontWeight: "bold" }}
              >
                <NavLink
                  className="data card-row-link"
                  to={`/dashboard/add-item-fields/${item?.item_code}`}
                  state={item?.item_name}
                  style={{ backgroundColor: "transparent", padding: 0 }}
                >
                  {item?.item_name}
                </NavLink>
              </li>
            )
          );
        })}
      </ul>
      {/* <div className="payslip-container">
        <span style={{ backgroundColor: "transparent", marginTop: "1rem" }}>
          or create a {"  "}
          <NavLink
            className="data card-row-link"
            to={`/dashboard/add-item-fields/copy-of-system-item`}
            state={"Item (Copy of System Item"}
            style={{
              backgroundColor: "transparent",
              padding: 0,
              fontWeight: "bold",
            }}
          >
            copy of an existing system item.
          </NavLink>
        </span>
      </div> */}
      <CustomButton
        title="Cancel"
        onClick={() => {
          // navigate("/dashboard/app-settings/custom-items");
          navigate("/dashboard/manage-global-report/manage-custom-report");
        }}
        type="secondary"
        styles={{
          marginTop: '1rem'
        }}
      />
    </div>
  );
};

export default AddCustomItems;
