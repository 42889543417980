import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { IoIosRibbon, IoIosSend } from "react-icons/io";
import { FaReceipt } from "react-icons/fa6";
import { MdOutlineFileDownload } from "react-icons/md";
import {
  CommonModal,
  CustomButton,
  DeactivateModal,
  Loader,
  Tooltip,
} from "../../../components";
import { TableHeader } from "../../../components/table";
import { Heading } from "../AppSettingsStyles";
import { IRASTable } from "../../filing/CPFFilingStyles";
import { TableRow } from "../../payslip/PayslipStyles";
import { Notify, Utils } from "../../../utils";
import { Constant } from "../../../config";
import { useEffect, useState } from "react";
import { LoaderContainer } from "../../../CommonStyles";
import { useLocation, useNavigate } from "react-router-dom";
import { ServiceCodeService } from "../../../_service";
import { useAppSelector } from "../../../_app";
import moment from "moment";
import ReconcileModal from "./ReconcileModal";

const CustomerInvoice = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serviceCodes, setServiceCodes] = useState<any>([]);
  const [isDeleteModalActive, setIsDeleteModalActive] = useState(false);
  const [serviceCodeToDelete, setServiceCodeToDelete] = useState<any>();
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const [modalData, setModalData] = useState();
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<any>(null);
  const [selectedInvoiceAmount, setSelectedInvoiceAmount] = useState<string>("");
  const [showReconcileModal, setShowReconcileModal] = useState<boolean>(false)
  const [open, setOpen] = useState(false)
  const [voidID, setVoidID] = useState('')
  const navigate = useNavigate();
  const location = useLocation();


  const user = useAppSelector((state) => state.user);

  const companyId = useAppSelector((state) => state.user.defaultCompnayId);

  const serviceCodeTableHeaders = [
    {
      name: "Invoice No.",
      width: "7%",
    },
    // {
    //   name: "Invoice No.",
    //   width: "16%",
    // },
    // {
    //   name: "Service Code",
    //   width: "16%",
    // },
    {
      name: "Inovice Date",
      width: "8%",
    },
    {
      name: "SPC Code",
      width: "10%",
    },

    {
      name: "Service Codes",
      width: "10%",
    },
    {
      name: "Description",
      width: "35%",
    },
    // {
    //   name: "Reconcile",
    //   width: "20%",
    // },
    {
      name: "Grand Total",
      width: "10%",
    },
    {
      name: "Status",
      width: "10%",
    },
    // {
    //   name: "Resend",
    //   width: "10%",
    // },
    {
      name: "Action",
      width: "10%",
    },
  ];

  const getInvoiceListing = async () => {
    setIsLoading(() => true);
    try {
      const { data, status } = await ServiceCodeService._getInvoiceList(
        companyId
      );
      if (status === 200) {
        setInvoiceData(data?.data);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  const handleResend = async (invoiceId: any) => {
    setIsLoading(() => true);
    try {
      const { status } = await ServiceCodeService._resendInvoice(invoiceId);

      if (status >= 200 && status < 300) {
        Notify("Resent Successfully", 1);
        getInvoiceListing();
      }
    } catch (error: any) {
      if (error?.response?.status) {
        Notify(error.response.data?.message, 0);
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }
    setIsLoading(() => false);
  };

  const handlePDFDownload = async (invoiceId: any) => {
    try {
      let response = await ServiceCodeService?._downloadInvoice(invoiceId);
      if (response.status >= 200 && response.status < 300) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `invoice.pdf`; // Set the desired filename for the downloaded file
        document.body.appendChild(a);
        a.click();
      }
    } catch (error) {
      // Notify(Constant.common.something_went_wrong, 0);
      Notify("No PDF Available", 0);
    }
  };

  useEffect(() => {
    getInvoiceListing();
  }, []);

  const handleReconcile = async (date: string, transactionId: string) => {
    let payload = {
      "invoice_id": selectedInvoiceId,
      "payment_received_date": date,
      "transaction_id": transactionId
    }

    try {
      const { data, status } = await ServiceCodeService._reconcileInvoice(
        payload
      );

      if (status === 200) {
        Notify("Reconcilation Successful!", 1);
        setShowReconcileModal(false);
        setSelectedInvoiceId(null);
        setSelectedInvoiceAmount("");
        getInvoiceListing();
      }
    } catch (error: any) {
      if (error.response.data?.message) {
        Notify(error.response.data?.message, 0);
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }

  }

  const voidActionHandler = async (data: any) => {

    try {
      const { status } = await ServiceCodeService._voidinvoiceid(
        { "invoice_id": data }
      )
      if (status === 200) {
        Notify("Void Successful!", 1);
        setSelectedInvoiceId(null)
        console.log('lol');

        getInvoiceListing();
      } else {
        setSelectedInvoiceId(null)

      }
    }
    catch (error: any) {
      if (error.response.data?.message) {
        Notify(error.response.data?.message, 0);
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }
  }

  return (
    <div>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "0.5rem",
              width: "100%"
            }}
          >
            <Heading>Customer Invoice</Heading>
            {user.permission === "full" &&
              <div style={{
                display: 'flex',
                alignItems: 'center'
              }}>
                {/* <CustomButton
                title="Auto Reconcile"
                type="secondary"
                onClick={() => null}
              /> */}
                <CustomButton
                  title="Add New Invoice"
                  type="secondary"
                  onClick={() => navigate("generate")}
                />
              </div>

            }

          </div>
          <IRASTable style={{
            width: "100%"
          }}>
            <TableHeader
              headerColumns={serviceCodeTableHeaders}
              variant="filled"
              styles={{ padding: "5px", textAlign: "left" }}
            />
            <tbody>
              {invoiceData?.length > 0 &&
                invoiceData
                  .sort((a: any, b: any) => b.number - a.number)
                  .map((item: any, index: any) => (
                    <TableRow style={{ cursor: 'pointer' }} key={item.id}>
                      <td onClick={() => navigate("generate", {
                        state: { ...item, 'isViewOnly': true },
                      })} style={{
                        textAlign: 'left',
                        paddingLeft: '1.5rem'
                      }}>{item?.number || ""}</td>

                      <td onClick={() => navigate("generate", {
                        state: { ...item, 'isViewOnly': true },
                      })} style={{
                        padding: '0.3125rem'
                      }}>{item?.date && ` ${moment(item?.date, "YYYY-MM-DD").format("DD MM YYYY").replaceAll(' ', '-')}`}</td>

                      {/* service code */}
                      <td
                      onClick={() => navigate("generate", {
                        state: { ...item, 'isViewOnly': true },
                      })}
                      >{item?.company_data?.spc_code || ""}</td>

                      <td onClick={() => navigate("generate", {
                        state: { ...item, 'isViewOnly': true },
                      })} style={{
                        padding: '0.3125rem'
                      }}>
                        {item?.json_data?.items?.map((x: any) => <li>{`${x?.name}`}</li>)}
                      </td>
                      {/* Description */}
                      {/* {item?.json_data?.items?.map((x:any)=>{`${x?.description}`})} */}

                      <td onClick={() => navigate("generate", {
                        state: { ...item, 'isViewOnly': true },
                      })}>

                        {item?.json_data.items.map((x: any) => <li> {x.description}</li>) || ""}

                      </td>


                      <td onClick={() => navigate("generate", {
                        state: { ...item, 'isViewOnly': true },
                      })} style={{
                        padding: '0.3125rem'
                      }}>{Utils.getCommanSepartedNumericStringWithDollar(item?.json_data?.grand_total || "")}</td>
                      <td onClick={() => navigate("generate", {
                        state: { ...item, 'isViewOnly': true },
                      })} style={{
                        padding: '0.3125rem'
                      }}>
                        <p style={{
                          textTransform:'capitalize'
                        }}>{item?.status === "payment_received" ? "Payment Received" : item.status}</p>
                      </td>
                      <td
                        style={{
                          display: 'flex',
                          gap: '1rem',
                          alignItems: 'center',
                          textAlign: "center",
                          padding: '0.3125rem'
                        }}
                      >
                        <Tooltip text="void">
                          <CustomButton
                            type={"secondary"}
                            size="small"
                            width="30px"
                            title={""}
                            onClick={() => {
                              setSelectedInvoiceId(() => item?.id);
                              setOpen(true)
                            }
                            }
                            icon={<IoIosRibbon color={(item?.status === "void" || item?.status === "payment_received") ? "gray" : ""} />}
                            disable={item?.status === "void" || item?.status === "payment_received"}

                          />
                        </Tooltip>
                        <Tooltip text="Payment Recieved">
                          <CustomButton
                            type={"secondary"}
                            size="small"
                            width="30px"
                            title={""}
                            onClick={(item?.status === "saved" || item?.status === "reconciled") ? () => null : () => {
                              setSelectedInvoiceId(() => item?.id);
                              setSelectedInvoiceAmount(() => Utils.getCommanSepartedNumericStringWithDollar(item?.json_data?.grand_total))
                              setShowReconcileModal(true)
                            }}
                            icon={<FaReceipt color={(item?.status === "saved" || item?.status === "payment_received" || item?.status === "void") ? "gray" : ""} />}
                            disable={item?.status === "saved" || item?.status === "payment_received" || item?.status === "void"}
                          />
                        </Tooltip>
                        <Tooltip text="Resend">
                          <CustomButton
                            type={"secondary"}
                            size="small"
                            width="30px"
                            title={""}
                            onClick={
                              () => {
                                handleResend(item?.id)
                                setOpen(false)
                              }
                            }
                            icon={<IoIosSend color={(item?.status === "void") ? "gray" : ""} />}
                            disable={item?.status === "void"}
                          />
                        </Tooltip>
                        <Tooltip text="Download">
                          <CustomButton
                            type={"secondary"}
                            size="small"
                            width="30px"
                            title={""}
                            onClick={() => handlePDFDownload(item?.id)}
                            icon={<MdOutlineFileDownload />}
                          />
                        </Tooltip>
                      </td>
                    </TableRow>
                  ))}
            </tbody>
          </IRASTable>

          <ReconcileModal heading="Payment Received" amount={selectedInvoiceAmount} open={showReconcileModal} handleClose={()=>{
            setShowReconcileModal(false);
            setSelectedInvoiceId(null);
            setSelectedInvoiceAmount("");
          }} onCancel={() => {
            setShowReconcileModal(false);
            setSelectedInvoiceId(null);
            setSelectedInvoiceAmount("");
          }} updateData={handleReconcile} />

          <CommonModal

            handleClose={() => {
              setSelectedInvoiceId(null)
              setOpen(false)
            }
            }
            open={open}
            updateData={() => {
              voidActionHandler(selectedInvoiceId)
              setOpen(false)
            }

            }
            body={`Are you sure you want to void customer invoice?`}
            heading="Confirm Void"
            conformButtonText="Yes"
            cancelButtonText="No"
            onCancel={() => {
              setSelectedInvoiceId(null)
              setOpen(false)
            }

            }

          />
        </>
      )}
    </div>
  );
};

export default CustomerInvoice;

