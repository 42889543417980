import { useEffect, useState } from "react";
import {
  CustomButton,
  CustomTextField,
  CustomNumberField,
  NavigateBack,
} from "../../../components";
import { Heading } from "../AppSettingsStyles";
import { Notify } from "../../../utils";
import { Constant } from "../../../config";
import { ServiceCodeService } from "../../../_service";
import { useLocation, useNavigate } from "react-router-dom";

const AddOrEditServiceCode = () => {
  const [servicecode, setServiceCode] = useState<string>("");
  const [fixedAmount, setFixedAmount] = useState<string>("");
  const [variableAmount, setVariableAmount] = useState<string>("");
  const [serviceDescription, setServiceDescription] = useState<string>("");
  const [heading, setHeading] = useState<string>("");

  const navigate = useNavigate();
  const location = useLocation();
console.log(location);

  const addServiceCode = async () => {
    try {
      const { status } = await ServiceCodeService._addServiceCode({
        name: servicecode,
        fixed_amount: fixedAmount,
        variable_amount: variableAmount,
        description: serviceDescription,
      });
      if (status === 201) {
        Notify("Service code added successfully", 1);
        navigate("/dashboard/manage-service-code");
      }
    } catch (error: any) {
      if (error?.response?.data?.name?.message) {
        Notify(error.response.data.name.message, 0);
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }
  };

  const editServiceCode = async () => {
    try {
      const { status } = await ServiceCodeService._editServiceCode(
        {
          name: servicecode,
          fixed_amount: fixedAmount,
          variable_amount: variableAmount,
          description: serviceDescription,
        },
        location.state.id
      );
      if (status === 200) {
        console.log("Location State: ", location.state.description);

        Notify("Service code edited successfully", 1);
        navigate("/dashboard/manage-service-code");
      }
    } catch (error: any) {
      if (error?.response?.status === 400) {
        if (error?.response?.data?.name?.message) {
          Notify(error.response.data.name.message, 0);
        } else {
          Notify(Constant.common.something_went_wrong, 0);
        }
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }
  };

  const saveButtonClick = () => {
    if (location.pathname.split("/").pop() === "edit") {
      editServiceCode();
    } else {
      addServiceCode();
    }
  };

  useEffect(() => {
    const path = location.pathname.split("/").pop();
    if (path === "edit" && location.state) {
      const { name, fixedAmount, variableAmount, description } = location.state;
      setServiceCode(name || ""); // Default to an empty string if undefined
      setFixedAmount(fixedAmount || "");
      setVariableAmount(variableAmount || "");
      setServiceDescription(description || "");
      setHeading("Edit Service Code");
    } else {
      setHeading("Add Service Code");
    }
  }, [location]);

  const validateServiceCode = (code: any) => {
    const isValidLength = code.length === 6;
    const isAlphanumeric = /^[a-zA-Z0-9]+$/.test(code);

    return code === ""
      ? ""
      : !isValidLength
        ? "Service code must be exactly 6 characters long."
        : !isAlphanumeric
          ? "Service code must be alphanumeric."
          : ""; 
  };
  const containsSpecialChar = (str: any) => /[^a-zA-Z0-9\s]/.test(str);
  return (
    <div>
      <div style={{display:'flex' , alignItems : 'center' , gap: '.5rem'}}>
      <NavigateBack />
      <Heading>{heading}</Heading>
      </div>
      <div style={{ margin: "40px 16px" }}>
        <CustomTextField
          title="Service Code"
          placeholder="Enter Service Code Here"
          value={servicecode} 
          onChangeText={(value) => {
            // Allow only alphanumeric and no special characters, also limit to 6 characters
            if (/^[a-zA-Z0-9]*$/.test(value) && value.length <= 6) {
                setServiceCode(value); // Update only if valid
            }
        }}
          error={validateServiceCode(servicecode)} 
        />

        {/* <CustomNumberField
          value={fixedAmount}
          onChangeText={setFixedAmount}
          error={
            fixedAmount
              ? isNaN(Number(fixedAmount)) || parseFloat(fixedAmount) <= 0
                ? "* Fixed amount must be a positive number and more than 0"
                : ""
              : ""
          }

        /> */}
        <div style={{
          display: 'flex',
          gap: '110px',
          marginTop: '10px',


        }}>


          {/* <CustomTextField
            title="Service Description"
            placeholder="Enter Service Code Here"
            value={serviceDescription}
            onChangeText={setServiceDescription}
            error={validateServiceCode(servicecode)}
  
          /> */}

          <label>Service Description</label>
          <textarea
            value={serviceDescription}
            onChange={(e) => setServiceDescription(e.target.value)}
            rows={4}
            placeholder="Enter Service Description"
            style={{
              borderRadius: "0.2rem",
              minWidth: '400px',
              padding: '2px',
              fontSize: '16px',
              border: '1px solid #ced4da'
            }}

          />
        </div>



        <CustomNumberField
          title="Fixed amount"
          value={fixedAmount}
          onChangeText={setFixedAmount}
          error={
            fixedAmount
              ? isNaN(Number(fixedAmount)) || parseFloat(fixedAmount) < 0
                ? "* Fixed amount must be a positive number"
                : ""
              : ""
          }

        />

        <CustomNumberField
          title="Amount per employee"
          value={variableAmount}
          onChangeText={setVariableAmount}
          error={
            variableAmount
              ? isNaN(Number(variableAmount)) || parseFloat(variableAmount) < 0
                ? "* Amount per employee must be a positive number"
                : ""
              : ""
          }

        />
      </div>
      <CustomButton
        title="Save"
        onClick={saveButtonClick}
        disable={
          !servicecode ||
          servicecode.length !== 6 || // Service code length validation
          !fixedAmount ||
          !variableAmount ||
          !serviceDescription ||
          isNaN(Number(fixedAmount)) ||
          isNaN(Number(variableAmount)) ||
          parseFloat(fixedAmount) <= 0 ||
          parseFloat(variableAmount) <= 0
        }
      />
    </div>
  );
};

export default AddOrEditServiceCode;
